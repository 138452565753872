<template>
  <b-container class="container-content form-container">
    <b-alert show variant="danger text-center" v-if="multiPackage.isAlreadyChangeMaid"
      >This package is already change maid, Please check your MP from new maid that you
      selected</b-alert
    >
    <b-alert variant="danger text-center" :show="!isMaidPublished && !isFetchingMaid">{{
      $t('warning.warning_maid_unpublished')
    }}</b-alert>
    <!-- <h1 >{{isTimeoutOfAppproval}}</h1> -->
    <div class="page-header marb8">
      <h4 class="page-header-text d-flex">
        {{
          isBookingMultiPackage
            ? $t('pages.booking.purchase_multi_package')
            : $t('pages.booking.multi_package_booking')
        }}
        <div class="d-flex position-relative">
          <b-img
            alt="information"
            src="../../assets/images/information-icon-black.svg"
            style="margin-right: 0.25em; margin-left: 0.25em; width: 70%"
            id="multipackage-icon"
          ></b-img>

          <b-tooltip
            target="multipackage-icon"
            placement="bottom"
            style="max-width: 100%"
            custom-class="veryLongTooltip"
          >
            {{ $t('guideline.multi_package_head_guideline') }} <br />
            &#9679; {{ $t('guideline.multi_package_guideline1') }} <br />
            &#9679; {{ $t('guideline.multi_package_guideline2') }} <br />
            &#9679; {{ $t('guideline.multi_package_guideline3') }} <br />
          </b-tooltip>
          <div v-if="!isBookingMultiPackage && !isFetchingBookingDetail">
            <b-badge
              pill
              :variant="badgeVaraint"
              class="font-weight-normal"
              :class="{
                'text-primary': isReview,
                'border-review': isReview,
              }"
              ref="status-badge-mp"
              >{{ badgeLabel }}
              <b-img
                src="../../assets/images/information-icon-white.svg"
                style="margin-top: -3px"
                v-if="tooltipText && !isReview"
              ></b-img>
              <b-img
                src="../../assets/images/information-icon-blue.svg"
                style="margin-top: -3px"
                v-if="tooltipText && isReview"
              ></b-img>
            </b-badge>
            <b-tooltip
              v-if="tooltipText"
              :target="() => $refs['status-badge-mp']"
              placement="bottom"
            >
              {{ tooltipText }}
            </b-tooltip>
          </div>
        </div>
      </h4>
    </div>
    <b-row>
      <b-col xl="8" offset-xl="2" class="marb8">
        <BookingStepWrapper :step="1">
          <div
            id="not-complete-location"
            v-if="isNotComplete && !isLocation"
            style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
            class="text-danger"
          >
            {{ $t('warning.please_complete_this_step') }}
          </div>
          <address-select
            v-model="bookingForm.location"
            :disabled="disabledAddressSelector"
            :add_details="bookingForm.location_additional_details"
            :prev="prevRoute"
            ref="address"
          ></address-select>

          <!-- Equipment Options -->
          <div
            id="not-complete-equipment"
            v-if="isNotComplete && !bookingForm.equipment"
            style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
            class="text-danger"
          >
            {{ $t('warning.please_complete_this_step') }}
          </div>
          <b-form-group>
            <template v-slot:label>
              {{ $t('form_label.equipment_options') }}
              <b-img
                alt="information"
                src="../../assets/images/information-icon-black.svg"
                style="margin-left: 0.25em"
                id="equipment-icon"
              ></b-img>
              <b-tooltip
                custom-class="equipment-tooltip"
                target="equipment-icon"
                placement="bottom"
                >{{ $t('form_label.equipment_description') }}</b-tooltip
              >
            </template>

            <v-select
              :labelTitle="$t('form_label.nothing_selected')"
              class="custom-v-select"
              :options="optionEquipments"
              v-model="bookingForm.equipment"
              :disabled="!isBookingMultiPackage"
            />
          </b-form-group>
          <div
            id="not-complete-services"
            v-if="isNotComplete && !isSelectServices"
            style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
            class="text-danger"
          >
            {{ $t('warning.please_complete_this_step') }}
          </div>
          <b-form-group :label="$t('form_label.services')">
            <v-select
              :labelTitle="$t('form_label.nothing_selected')"
              class="custom-v-select"
              :options="optionServices"
              v-model="bookingForm.services"
              :disabled="!(isBookingMultiPackage || isMyBookingMultiPackage)"
            />
          </b-form-group>
        </BookingStepWrapper>
      </b-col>
      <b-col xl="8" offset-xl="2" class="marb8">
        <BookingStepWrapper :step="2">
          <div
            id="not-complete-maid"
            v-if="isNotComplete && !bookingForm.maid"
            style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
            class="text-danger"
          >
            {{ $t('warning.please_complete_this_step') }}
          </div>
          <b-form-group class="d-xl-block mb-0">
            <b-row>
              <b-col v-if="bookingForm.maid" cols="10">
                <maid-detail
                  class="booking"
                  :detail="bookingForm.maid"
                  :hideAddress="true"
                  :hasButton="showChatBtn"
                  :disableButton="isMultiPackageCompleted"
                  :buttonText="$t('button.call_maid')"
                  @onClickButton="toCallMaid"
                  :isOutOfRadius="isUserLocationOutOfMaidRadius"
                ></maid-detail>
              </b-col>
              <b-col cols="auto" v-if="!bookingForm.maid">
                <img class="avatar" src="@/assets/images/avatar_default.jpg" alt />
              </b-col>
              <b-col v-if="!bookingForm.maid">
                <h6>{{ $t('form_label.please_select_the_maid') }}</h6>
                <b-button
                  variant="warning"
                  block
                  @click="chooseMaid"
                  :disabled="
                    !bookingForm.location.main_text ||
                    !isSelectBringEquipment ||
                    !bookingForm.services
                  "
                  >{{ $t('button.choose_maid') }}</b-button
                >
              </b-col>
              <b-col
                class="text-right"
                v-if="
                  bookingForm.maid &&
                  !isCanceledByCustomer &&
                  !multiPackage.isAlreadyChangeMaid &&
                  !isFetchingBookingDetail &&
                  !isMultiPackageCompleted
                "
              >
                <b-link @click="chooseMaid">
                  <i class="far fa-edit" style="font-size: 1.25em"></i>
                </b-link>
              </b-col>
              <b-col class="d-none d-sm-block" v-if="!bookingForm.maid"></b-col>
            </b-row>
          </b-form-group>
          <ErrorsMaidMisMatch
            v-if="
              (isMultiPackageCompleted ||
                isBookingMultiPackage ||
                (isUpdateServices ? IronText : false)) &&
              (isMaidPublished || isFetchingMaid)
            "
            class="mt-3"
            :errors="[
              equipmentError,
              notProvidedServicesError,
              isUserLocationOutOfMaidRadius ? $t('warning.maid_out_of_radius') : '',
              isaAllergicMaid,
            ]"
          />
        </BookingStepWrapper>
      </b-col>
      <b-col xl="8" offset-xl="2" class="marb8">
        <BookingStepWrapper :step="3">
          <b-form-group>
            <div style="position: relative">
              <v-calendar
                :key="calendarKey"
                :locale="$i18n.locale"
                ref="calendar"
                :min-date="minDate"
                class="v-calendar-custom"
                :attributes="calendarAttrs"
                is-expanded
                @dayclick="onSelectedDate"
                @update:from-page="onMonthChanged"
              ></v-calendar>
              <div style="top: 0; right: 50%; position: absolute; transform: translateX(550%)">
                <b-img
                  :src="require('@/assets/images/information-icon-black.svg')"
                  v-b-tooltip.hover.html="$t('form_label.calendar_tooltip')"
                  @click.stop
                  class="mt-3"
                  style="z-index: 100"
                ></b-img>
              </div>
            </div>
            <CalendarDetail
              v-if="shouldShowCalendarDetail"
              :selectedDate="selectedDate"
              :maidCalendars="maidAvailableTimeSlots"
            />
          </b-form-group>
          <div
            id="not-complete-accommodation"
            v-if="isNotComplete && !isSelectAccommodation"
            style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
            class="text-danger"
          >
            {{ $t('warning.please_complete_this_step') }}
          </div>
          <b-form-group :label="$t('form_label.accommodation')">
            <v-select
              :labelTitle="$t('form_label.nothing_selected')"
              class="custom-v-select"
              :options="optionAccommodation"
              v-model="bookingForm.accommodation"
              :disabled="!isBookingMultiPackage"
            />
          </b-form-group>
          <div
            id="not-complete-duration-package"
            v-if="isNotComplete && (!bookingForm.duration || !bookingForm.package)"
            style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
            class="text-danger"
          >
            {{ $t('warning.please_complete_this_step') }}
          </div>
          <small>{{ $t('form_label.please_choose_multi_package') }}</small>
          <div
            style="margin-bottom: -10px"
            :class="recommendDurationTextClass ? 'text-success' : 'text-danger'"
          >
            {{ recommendDurationText }}
          </div>
          <div class="mb-3"></div>
          <b-form-row class="custom-input-datetime">
            <b-col cols="6">
              <b-form-group :label="$t('form_label.hours')">
                <v-select
                  :labelTitle="$t('form_label.choose_hours')"
                  :options="optionHours"
                  v-model="bookingForm.duration"
                  class="custom-v-select"
                  :disabled="
                    bookingForm.maid === null || !isBookingMultiPackage || isInitializeTimeSlot
                  "
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group :label="$t('form_label.multi_times')">
                <v-select
                  :labelTitle="$t('form_label.choose_multi_times')"
                  :options="optionTimes"
                  v-model="bookingForm.package"
                  class="custom-v-select"
                  :disabled="
                    bookingForm.duration === null || !isBookingMultiPackage || isInitializeTimeSlot
                  "
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <small>{{ $t('form_label.please_choose_recurring_weekday') }}</small>

          <!-- Tooltip Hours -->
          <b-img
            alt="information"
            src="../../assets/images/information-icon-black.svg"
            style="margin-right: 0.25em; margin-top: -3px; margin-left: 0.25em"
            id="hours-icon"
          ></b-img>

          <b-tooltip
            target="hours-icon"
            placement="bottom"
            style="max-width: 100%"
            custom-class="weekday-tooltip"
          >
            {{ $t('guideline.please_choose_recurring_weekday') }}
          </b-tooltip>

          <b-form-row class="mt-4">
            <b-col cols="5">
              <b-form-group :label="$t('form_label.recurrent_date')" class="mb-0"></b-form-group>
            </b-col>
            <b-col cols="7">
              <b-form-group :label="$t('form_label.time')" class="mb-0"></b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-for="(rc, index) in bookingForm.recurrentDates" :key="index">
            <b-col cols="5">
              <b-form-group>
                <v-select
                  :labelTitle="$t('form_label.nothing_selected')"
                  :options="optionsDays"
                  v-model="rc.date"
                  class="custom-v-select"
                />
              </b-form-group>
            </b-col>
            <b-col cols="7">
              <div class="d-flex">
                <v-select
                  :labelTitle="$t('form_label.nothing_selected')"
                  :options="recurrentTimeOptions"
                  v-model="rc.time"
                  class="custom-v-select with-del-btn"
                />
                <b-button
                  variant="primary"
                  class="recurrent-btn-delete"
                  @click="deleteRecurrentDate(index)"
                >
                  <i class="fas fa-plus" style="font-size: 1.25em"></i>
                </b-button>
              </div>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="5">
              <b-form-group class="mb-0">
                <v-select
                  ref="recurrentDateSelect"
                  :labelTitle="$t('form_label.select_date')"
                  :options="optionsDays"
                  v-model="recurrentDate"
                  class="custom-v-select"
                  :disabled="disableReccurentDate"
                />
              </b-form-group>
            </b-col>
            <b-col cols="7">
              <b-form-group class="mb-0">
                <v-select
                  ref="recurrentTimeSelect"
                  :labelTitle="$t('form_label.select_time')"
                  :options="recurrentTimeOptions"
                  v-model="recurrentTime"
                  class="custom-v-select"
                  :disabled="!recurrentDate"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
        </BookingStepWrapper>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="8" offset-xl="2" v-if="bookingForm.package" id="recurrent-timeslot" class="marb8">
        <BookingStepWrapper :step="4">
          <div
            id="not-complete-time-slot"
            v-if="
              isNotComplete &&
              (!multiPackage.recurrentList.some((rc) => !!rc.start_datetime) ||
                !hasAvailableTimeSlot)
            "
            style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
            class="text-danger"
          >
            {{ $t('warning.please_complete_this_step') }}
          </div>
          <div class="tabs-time-slots">              
        
            <b-form-group
              :label="`${
                bookingForm.isChangeMaid
                  ? $t('form_label.set_new_dates_times')
                  : $t('form_label.set_dates_times')
              }`"
            >    
            <package-tab class="mb-3"/>
              <div>
                <!-- <small 
                  >
                  <li>{{ $t('form_label.please_select_at_least_one_available_date_1') }}</li>
                  <li>{{ $t('form_label.please_select_at_least_one_available_date_2') }}</li>
                  <li>{{ $t('form_label.please_select_at_least_one_available_date_3') }}</li>
                </small> -->
                <li>
                <small v-if="!isMultiPackageCompleted || isBookingMultiPackage">{{
                $t('form_label.book_at_least_1_credit')
                }}</small>
                </li>

                <li>
                <small v-if="!isMultiPackageCompleted || isBookingMultiPackage">{{
                $t('form_label.empty_credits')
                }}</small>
                </li>

                <li>
                <small v-if="!isMultiPackageCompleted || isBookingMultiPackage">{{
                $t('form_label.package_credits_are_valid_for_90_days')
                }}</small>
                </li>
              </div>
            </b-form-group>
          </div>
        </BookingStepWrapper>
      </b-col>
      <b-col xl="8" offset-xl="2" class="marb8">
        <BookingStepWrapper :step="bookingForm.package ? 5 : 4">
          <b-form-group :label="$t('form_label.additional_info')">
            <b-form-checkbox-group
              v-model="bookingForm.additional"
              :disabled="!isBookingMultiPackage"
            >
              <b-form-checkbox
                v-for="option in options.additional"
                :key="option.text"
                :value="option.value"
                >{{ $t(`check_box.${option.text}`) }}</b-form-checkbox
              >
            </b-form-checkbox-group>
          </b-form-group>
          <b-form-group>
            <b-textarea
              :placeholder="$t('form_label.additional_placeholder')"
              rows="4"
              v-model="bookingForm.description"
              :disabled="!isBookingMultiPackage"
            ></b-textarea>
            <p v-if="generateErrIsTrue" class="text-danger mt-1">
              {{ $t('pages.booking.the_maximum_length') }}
            </p>
          </b-form-group>

          <!-- Error Text -->

          <!-- <ErrorsMaidMisMatch
            v-if="
              (isMultiPackageCompleted || isBookingMultiPackage || IronText) &&
              !(!isMaidPublished && !isFetchingMaid)
            "
            :errors="[equipmentError, notProvidedServicesError]"
          /> -->

          <div class="d-flex justify-content-between mb-3" v-if="isCanceledByCustomer">
            <p class="m-0">{{ $t('form_label.refund') }} ( {{ $t('alias.thb') }} - ฿ )</p>
            <p class="m-0 text-dark">฿{{ (grandTotal * 0.95) | toCurrencyNumber(true) }}</p>
          </div>
          <div class="d-flex justify-content-between mb-3" v-if="isCanceledByCustomer">
            <p class="m-0">{{ $t('form_label.cancellation_fee') }} ( {{ $t('alias.thb') }} - ฿ )</p>
            <p class="m-0 text-dark">฿{{ (grandTotal * 0.05) | toCurrencyNumber(true) }}</p>
          </div>
          <div class="d-flex justify-content-between" v-else>
            <p class="m-0" v-if="!isUpdate">Total</p>
            <p class="m-0 text-dark" v-if="!isUpdate">฿{{ grandTotal | toCurrencyNumber(true) }}</p>
          </div>
          <hr />
          <div
            id="not-complete-policy"
            v-if="isNotComplete && !isAgreePolicy"
            style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
            class="text-danger"
          >
            {{ $t('warning.please_complete_this_step') }}
          </div>
          <!-- Check box MP pages -->
          <b-form-checkbox
            id="agreement-checkbox"
            v-model="isAgreePolicy"
            :value="true"
            class="my-2"
            v-if="!isCanceledByCustomer && !isMultiPackageCompleted && isCheckboxRender"
          >
            <span>{{ $t('form_label.i_understand_that_bluuu') }}</span>
            <span @click="openServiceModal('Terms')" style="cursor: pointer">{{
              $t('form_label.terms_and_conditions_of_use')
            }}</span>
            <span>{{ $t('form_label.and') }}</span>
            <span @click="openServiceModal('Policy')" style="cursor: pointer">
              {{ $t('form_label.scope_of_service') }}
            </span>
          </b-form-checkbox>

          <!-- <li>
          <small v-if="!isMultiPackageCompleted || isBookingMultiPackage">{{
            $t('form_label.book_at_least_1_credit')
          }}</small>
          </li>

          <li>
          <small v-if="!isMultiPackageCompleted || isBookingMultiPackage">{{
            $t('form_label.empty_credits')
          }}</small>
          </li>

          <li>
          <small v-if="!isMultiPackageCompleted || isBookingMultiPackage">{{
            $t('form_label.package_credits_are_valid_for_90_days')
          }}</small>
          </li> -->

          <div class="mb-3"></div>
          <b-form-row v-if="!multiPackage.isAlreadyChangeMaid && !isFetchingBookingDetail">
            <b-col v-if="cancelable"
              ><b-button
                variant="danger"
                class="py-2"
                block
                :disabled="hasUpcomingBooking || isReview"
                @click="toggleCancelModal"
                >{{ $t('button.cancel_booking') }}</b-button
              >
            </b-col>

            <b-col v-if="shoudShowBookingButton">
              <b-button
                variant="warning"
                size="md"
                class="py-2 text-white"
                :class="{ disabled: isBookingMultiPackage ? !canBooking : !canUpdate }"
                block
                @click="bookNow"
                :disabled="!isBookingMultiPackage && !canUpdate"
                >{{
                  isBookingMultiPackage ? $t('button.purchase') : $t('button.confirm_update')
                }}</b-button
              >
            </b-col>
          </b-form-row>
          <div
            class="my-4 d-flex justify-content-center align-items-center"
            v-if="!isBookingMultiPackage"
          >
            <button @click="onClose" class="btn-close">
              <i class="fas fa-times"></i>
              {{ $t('button.close') }}
            </button>
          </div>
        </BookingStepWrapper>
      </b-col>
    </b-row>
    <BaseModal
      id="warning-reset-time-slots"
      :noCloseOnBackdrop="true"
      :noCloseOnEsc="true"
      @close="restorePreviousMultiTime"
    >
      <p v-if="modalConfirmType === 'reduceTimeSlots'">
        {{ $t('warning.time_slot_will_be_delete') }}
      </p>
      <p v-else-if="modalConfirmType === 'changeMaid'">
        {{ $t('warning.time_slot_will_be_reset_change_maid') }}
      </p>
      <p v-else>{{ $t('warning.time_slot_will_be_reset') }}</p>
      <b-button variant="warning" @click="confirmAction" block>{{ $t('button.confirm') }}</b-button>
    </BaseModal>

    <!-- Scope of Service & Service of Policies Modal -->
    <BaseModal id="agreement-modal" size="lg">
      <ServicesContent />
    </BaseModal>

    <BaseModal
      id="require-login-modal"
      :noCloseOnBackdrop="true"
      :noCloseOnEsc="true"
      :hideCloseButton="true"
    >
      <p>{{ $t('warning.please_login_first') }}</p>
      <b-button variant="primary" @click="doSignIn" block>{{ $t('button.sign_in') }}</b-button>
    </BaseModal>

    <BaseModal id="error-form">
      <p class="text-danger">
        {{ $t('form_label.credit_cannot_have_overlapping') }}
      </p>
      <ul class="list-unstyled">
        <li v-for="(rc, index) in notAvailableTimeSlots" :key="index">
          {{ rc.number }}) {{ rc.start_datetime | formatDate('MMM D ddd H:mm') }} -
          {{ rc.end_datetime | formatDate('H:mm') }}
        </li>
      </ul>
    </BaseModal>
    <BaseModal id="warning-remaining-credits">
      <p v-html="$t('warning.warning_remaining_credits')"></p>
      <b-button block variant="warning" @click="doBooking">{{ $t('button.purchase') }}</b-button>
    </BaseModal>

    <BaseModal id="warning-invalid-datetime">
      <div class="text-center">
        <p>{{ $t('error.invalid_datetime') }}</p>
        <p class="text-timestamp">{{ dateTimeTimezone }}</p>
      </div>
    </BaseModal>

    <BaseModal id="warning-limit-change-maid">
      <p>{{ $t('warning.warning_limit_change_maid') }}</p>
      <b-button block variant="primary" @click="contactWithAdmin">
        <i class="fas fa-comment-alt"></i>
        {{ $t('button.contact_admin') }}
      </b-button>
    </BaseModal>

    <BaseModal id="cancel-booking-modal">
      <p class="my-2">{{ $t('warning.are_you_sure_to_cancel') }}</p>
      <p>{{ $t('warning.if_you_need_change_booking') }}</p>
      <p>
        {{ $t('warning.if_you_cancel') }}
      </p>
      <p>
        {{ $t('warning.to-receive-your-refund') }}
      </p>
      <b-button variant="danger" block class="py-2" @click="doCancelBooking">{{
        $t('button.cancel_booking')
      }}</b-button>
    </BaseModal>
    <BaseModal id="inquire-delete-timeslot-modal" @close="onCloseInquireDeleteModal">
      <div class="pl-4">
        <p class="mb-0" v-for="(timeslot, i) in unusedPassedTimeSlot" :key="i">
          {{ i + 1 }}) {{ timeslot.start_datetime | formatDate('ddd D MMMM, H:mm') }} -
          {{ timeslot.end_datetime | formatDate('H:mm') }}
        </p>
      </div>
      <p class="my-3">{{ $t('warning.inquire_delete_timeslot') }}</p>
      <b-button variant="warning" block class="py-2" @click="confirmDeletePassedTimeSlot">{{
        $t('button.confirm')
      }}</b-button>
    </BaseModal>
    <BaseModal id="maid-phone-number">
      <div class="text-center py-5" v-if="bookingForm.maid">
        <p class="h5 text-primary">
          {{ $t('modal.maid_phone_number') }} : {{ bookingForm.maid.user.phone }}
        </p>
      </div>
    </BaseModal>
    <BaseModal
      id="confirm-condo-modal"
      :noCloseOnBackdrop="true"
      :noCloseOnEsc="true"
      :hideCloseButton="true"
    >
      <div class="text-center mt-3">
        <p>{{ $t('warning.condo_warning') }}</p>
        <b-form-checkbox
          v-if="isLoggedIn"
          id="confirm-condo-checkbox"
          v-model="dontShowCondoWarning"
          :value="true"
          :unchecked-value="false"
        >
          <span>{{ $t('warning.dont_show_again') }}</span>
        </b-form-checkbox>
        <b-button variant="primary" class="py-2 px-4 mt-3" @click="confirmCondoWarning">
          {{ $t('button.confirm') }}
        </b-button>
      </div>
    </BaseModal>
    <transition name="fade" mode="out-in">
      <FullContentLoader />
    </transition>
  </b-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { Vue, Component, Watch } from 'vue-property-decorator'
import ApiClient from '../../services/'
import VSelect from '@alfsnd/vue-bootstrap-select'
import AddressSelect from '../../components/AddressSelect'
import PackageTab from '../../components/PackageTab'
import MaidDetail from '../../components/MaidDetail'
import Constant, { ENABLE_FILTER_MAID_BY_USER_LOCATION, STATUS } from '../../config/booking'
import moment from 'moment'
import BaseModal from '../../components/Modal/BaseModal'
import ServicesContent from '../../components/Modal/ServicesContent'
import PoliciesContent from '../../components/Modal/PoliciesContent'
import CalendarDetail from '../../components/CalendarDetail'

import {
  createTimeSlotItem,
  removeDuplicateTimeSlot,
  createEmptyTimeSlot,
  minimumBookingMoment,
  isToday,
  clone,
} from '../../utils'

import {
  getDisabledDateFromTimeSlot,
  isDateInTimeSlots,
  sortTimeSlots,
} from '../../helpers/calendar'

import { createMomentByRepeatDay } from '../../helpers/calendar'
import VueContentLoading from 'vue-content-loading'
// import { ADMIN_ID } from '../../store/sbConstants'

import FullContentLoader from '../../components/FullContentLoader'
import BookingStepWrapper from '../../components/BookingStepWrapper'
import ErrorsMaidMisMatch from '../../components/ErrorsMaidMisMatch'

import { equipmentMixin } from '../../mixins/booking/equipmentMixin'
import { serviceMixin } from '../../mixins/booking/serviceMixin'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { v4 as uuidv4 } from 'uuid'

const BookingStore = createNamespacedHelpers('booking')
const AuthStore = createNamespacedHelpers('auth')
const MaidStore = createNamespacedHelpers('maid')
const CalendarStore = createNamespacedHelpers('calendar')
// const sbUser = createNamespacedHelpers('sbUser')
// const sbChannel = createNamespacedHelpers('sbChannel')
const Calendar = createNamespacedHelpers('calendar')
const UserStore = createNamespacedHelpers('user')
Component.registerHooks(['beforeRouteEnter'])

@Component({
  components: {
    VSelect,
    AddressSelect,
    PackageTab,
    MaidDetail,
    BaseModal,
    ServicesContent,
    PoliciesContent,
    CalendarDetail,
    VueContentLoading,
    FullContentLoader,
    BookingStepWrapper,
    ErrorsMaidMisMatch,
  },
  computed: {
    ...BookingStore.mapState(['multiPackage', 'currentBookingMultiPackage', 'bookingReview']),
    ...BookingStore.mapGetters([
      'calendarForNewBookingPayload',
      'calendarForUpdateBookingPayload',
      'calendarChangedMaid',
    ]),
    generateErrIsTrue() {
      let x = this.bookingForm.description

      if (x) {
        if (x.length <= 500) {
          return false
        } else {
          x = x.slice(0, 499)
          this.bookingForm.description = x
          return true
        }
      }
    },
    ...AuthStore.mapState(['isLoggedIn', 'callBackPathAfterWelcomePage', 'isNewUser']),
    ...CalendarStore.mapGetters([
      'calendarTotalHours',
      'baseDisabledHighlight',
      'maidAvailableTimeSlots',
      'maidDisabledHighlight',
    ]),
    ...CalendarStore.mapState([
      'isFetchingCalendar',
      'isInitializeTimeSlot',
      'renderCalendarCount',
    ]),
    ...UserStore.mapState(['accommodation_confirmed']),
    // ...sbChannel.mapState(['sbChannelList']),
    // ...sbUser.mapState(['sbUser']),
  },
  methods: {
    ...BookingStore.mapMutations([
      'updateBookingMultiPackage',
      'setBookingReview',
      'setTimeSlotsMultiPackage',
      'setMaidMultiPackage',
      'setBookingChangedMaid',
      'updateRecurrentItem',
    ]),
    ...BookingStore.mapActions([
      'checkBookingCopy',
      'createTimeSlotsFromBookingCalendar',
      'createTimeSlotsMultiPackage',
      'fetchCalendarForTimeSlots',
      'fetchMaidDetailForBookingChangedMaid',
    ]),
    ...AuthStore.mapActions(['signIn']),
    ...AuthStore.mapMutations(['setCallBackPathAfterWelcomePage']),
    ...CalendarStore.mapActions([
      'isAvailableTimeSlot',
      'updateMaidCalendarByMonth',
      'fetchMaidCalendarTotalHours',
      'fetchMaidCalendarAllMonth',
      'fetchMaidCalendarAllMonthPublic',
    ]),
    ...Calendar.mapMutations(['incrementRenderCount']),
    ...CalendarStore.mapMutations(['setCurrentSelectedOnCalendar']),
    ...MaidStore.mapActions(['inquiryMaidOutOfRadius']),
    ...UserStore.mapActions(['updateUserAccommodation']),
  },
  mixins: [equipmentMixin, serviceMixin],
})
export default class BookingMultiPackage extends Vue {
  options = {
    hours: Constant.MULTI_HOURS,
    multiTimes: Constant.MULTI_TIMES,
    accommodation: Constant.ACCOMMODATION,
    additional: Constant.ADDITIONAL_INFO,
    services: Constant.SERVICES,
    recurrentDate: Constant.RECURRENT_DATES,
  }

  get optionHours() {
    return Constant.MULTI_HOURS.map((option) => ({
      text: `${option.value} ${this.$t('form_label.hrs')}`,
      value: option.value,
    }))
  }

  get optionTimes() {
    return Constant.MULTI_TIMES.map((option) => ({
      text: `${option.value} ${this.$t('commons.times')}`,
      value: option.value,
    }))
  }

  get optionsDays() {
    return Constant.RECURRENT_DATES.map((option) => ({
      text: this.$t(`day.${option.value}`),
      value: option.value,
    }))
  }

  get optionAccommodation() {
    return Constant.ACCOMMODATION.map((option) => ({
      ...option,
      text: this.$t(`accommodation.${option.text}`),
    }))
  }

  get optionServices() {
    return Constant.SERVICES.map((option) => ({
      ...option,
      text: this.$t(`services.${option.text}`),
    }))
  }

  recurrentDate = {
    date: null,
    time: null,
  }
  recurrentTime = null
  recurrentDate = null
  publish_status = 'PUBLISHED'
  bookingForm = {
    id: null, // for update booking only (unneccessary for new booking)
    date: null,
    package: null,
    location: {
      description: '',
      main_text: '',
      place_id: '',
      secondary_text: '',
    },
    equipment: null,
    accommodation: null,
    duration: null,
    services: null,
    additional: [],
    method: 0, // 0 = Auto, 1 = Manual
    maid: null,
    recurrentDates: [],
    recurrentList: [],
    recurrentItems: {},
    description: '',
    isChangeMaidBefore: false,
    isChangeMaid: false,
    currentMaid: null,
    historyRecurrentItems: [],
    bookingCalendars: [],
    bookingChangedMaid: [],
    notBookedTimes: 0,
    bookedTimes: 0,
    canChangeMaid: true,
    isAlreadyChangeMaid: false, // Booking นี้ถูกเปลี่ยนเมดไปแล้วหรือยัง ?,
    payment: null,
    preventFetchBooking: false,
    location_additional_details: null,
  }
  timeDetailForSameDay = null
  deleteRecurrentDateIndex = null
  modalConfirmType = ''
  totalPrice = 0
  calendar = []
  isAgreePolicy = false

  notAvailableTimeSlots = [] // วันที่ไม่ว่างใน recurrent items

  selectedDate = '' // ใช้้สำหรับแสดงรายละเอียดใต้ปฏิทิน
  previousMultiTimesOptions = null // ใช้สำหรับ Restore ค่า option เดิม
  previousDurationOptions = null // ใช้สำหรับ Restore ค่า option เดิม

  // Calendar Attributes
  maidDisabledDates = [] // วันที่เมดไม่รับงาน
  disabledDatesAttrs = [] // วันที่ไม่สามารถกดได้บนปฏิทิน

  errors = [] // key of errors
  canChangeMaid = true

  descriptionTimeout = null
  isRestoring = false
  isFetchingBookingDetail = false
  isFetchingMaid = false
  isShowTooltip = false
  unusedPassedTimeSlot = [] // timeslot ที่กำลังจะลบออก
  isMaidOutOfRadius = false
  isFromMP = false
  prevRoute = {
    name: '',
  }
  calendarKey = 0
  isNotComplete = false

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  }

  get recommendDurationText() {
    if (!this.bookingForm.accommodation || this.bookingForm.accommodation.value == 'OTHERS')
      return ''
    const recommendDuration = {
      CONDO_1_BR: `3 - 4 ${this.$t('alias.hour')}`,
      CONDO_2_BR: `4 - 5 ${this.$t('alias.hour')}`,
      CONDO_3_BR: `5 - 6 ${this.$t('alias.hour')}`,
      CONDO_4_BR: `6 - 8 ${this.$t('alias.hour')}`,
      HOUSE_1_STORY: `3 - 4 ${this.$t('alias.hour')}`,
      HOUSE_2_STORIES: `4 - 6 ${this.$t('alias.hour')}`,
      HOUSE_3_STORIES: `6 - 8 ${this.$t('alias.hour')}`,
      OFFICE: `3 - 4 ${this.$t('alias.hour')}`,
    }
    return `${this.$t('form_label.recommend_duration')} ${
      recommendDuration[this.bookingForm.accommodation.value]
    }`
  }

  get recommendDurationTextClass() {
    if (!this.bookingForm.accommodation || !this.bookingForm.duration) return false
    const num = this.bookingForm.duration.value
    switch (this.bookingForm.accommodation.value) {
      case 'CONDO_1_BR':
        return num >= 3
      case 'CONDO_2_BR':
        return num >= 4
      case 'CONDO_3_BR':
        return num >= 5
      case 'CONDO_4_BR':
        return num >= 6
      case 'HOUSE_1_STORY':
        return num >= 3
      case 'HOUSE_2_STORIES':
        return num >= 4
      case 'HOUSE_3_STORIES':
        return num >= 6
      case 'OFFICE':
        return num >= 3
      default:
        return false
    }
  }

  get currentSelectDate() {
    const date = this.calendar[1].dates
    if (!date) return ''
    return moment(date).format('MMM D, dddd')
  }

  get overlapRecurrentDates() {
    const recurrentList = this.multiPackage.recurrentList
      .filter((rc) => rc.start_datetime && moment() < moment(rc.start_datetime)) // filter null datetime
      .sort((a, b) => {
        return moment(a.start_datetime) - moment(b.start_datetime)
      }) // เรียงจากน้อยไปมาก
    const overlapDates = {}

    let totalDates = recurrentList.length
    for (let i = 0; i < totalDates; i++) {
      if (i !== 0) {
        const prevEndDateTime = moment(recurrentList[i - 1].end_datetime)
        const currentStartDateTime = moment(recurrentList[i].start_datetime)
        const currentEndDateTime = moment(recurrentList[i].end_datetime)
        if (
          currentStartDateTime < prevEndDateTime || // start datetime อยู่ในช่วงของวันเวลาอันก่อนหน้า
          currentEndDateTime < prevEndDateTime // end datetime อยู่ในช่วงของวันเวลาอันก่อนหน้า
        ) {
          overlapDates[i] = recurrentList[i]
          overlapDates[i - 1] = recurrentList[i - 1]
        }
      }
    }
    return Object.values(overlapDates)
  }

  get requireCheckMaidRadius() {
    return Boolean(
      get(this.bookingForm, 'maid.id') &&
        // this.isBookingMultiPackage &&
        get(this.bookingForm, 'location.latitude') &&
        get(this.bookingForm, 'location.longitude')
    )
  }

  get isUserLocationOutOfMaidRadius() {
    return this.requireCheckMaidRadius && this.isMaidOutOfRadius && !this.disabledAddressSelector
  }
  get isCheckboxRender() {
    let route = this.$route.params.id
    let render = true
    if (route > 0) {
      render = false
    }
    return render
  }

  get isaAllergicMaid() {
    if (this.bookingForm.additional.length > 0 && this.bookingForm.maid) {
      const allergic_cat =
        this.bookingForm.additional.includes('HAS_CAT') &&
        this.bookingForm.maid.additional_infos.includes('ALLERGIC_CAT')
      const allergic_dog =
        this.bookingForm.additional.includes('HAS_DOG') &&
        this.bookingForm.maid.additional_infos.includes('ALLERGIC_DOG')

      if (allergic_cat && allergic_dog) {
        return this.$t('warning.allergic_cat_dog')
      } else if (allergic_cat) {
        return this.$t('warning.allergic_cat')
      } else if (allergic_dog) {
        return this.$t('warning.allergic_dog')
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  get canUpdate() {
    console.log(
      'canUpdate',
      this.isUserChangeAnyField,
      this.isSelectAccommodation,
      this.isSelectServices,
      !this.bookingForm.isAlreadyChangeMaid,
      this.isSelectAtLeastOneTimeSlot,
      this.isMaidPublished,
      !this.hasEquipmentError,
      this.isUpdateServices ? this.isSelectedServiceOptionValidWithMaid : true,
      !this.isUserLocationOutOfMaidRadius,
      !this.isReview
    )
    return (
      this.isUserChangeAnyField &&
      this.isSelectAccommodation &&
      this.isSelectServices &&
      !this.bookingForm.isAlreadyChangeMaid &&
      this.isSelectAtLeastOneTimeSlot &&
      this.isMaidPublished &&
      !this.hasEquipmentError &&
      (this.isUpdateServices ? this.isSelectedServiceOptionValidWithMaid : true) &&
      !this.isUserLocationOutOfMaidRadius &&
      !this.isReview
    )
  }

  get isApproved() {
    return this.booking_calendars.some(({ status }) => status === STATUS.approved)
  }

  get isSelectAccommodation() {
    return this.bookingForm.accommodation !== null
  }

  get isSelectServices() {
    return this.bookingForm.services !== null
  }

  get canBooking() {
    const hasSomeRecurrentDate = this.multiPackage.recurrentList.some((rc) => !!rc.start_datetime) // must have at least 1 time slot
    return (
      hasSomeRecurrentDate &&
      this.isLocation &&
      this.hasAvailableTimeSlot &&
      this.isSelectAccommodation &&
      this.isSelectServices &&
      this.isAgreePolicy &&
      !this.bookingForm.isAlreadyChangeMaid &&
      this.isMaidPublished &&
      !this.hasEquipmentError &&
      this.isSelectedServiceOptionValidWithMaid &&
      !this.isUserLocationOutOfMaidRadius &&
      !this.isaAllergicMaid
    )
  }

  // ============= Validate form data ===============

  /**
   * Return Boolean
   * return true ถ้ามี User เลือก recurrent แล้วอย่างน้อย 1 วัน
   */
  get isSelectAtLeastOneTimeSlot() {
    if (this.isBookingMultiPackage) {
      return this.calendarForNewBookingPayload.length > 0
    } else {
      // Update Booking
      return (
        this.calendarForUpdateBookingPayload.length > 0 ||
        (this.hasUsedTimeSlot && !this.multiPackage.isChangeMaid)
      )
    }
    // return this.multiPackage.recurrentList.some(rc => !!rc.start_datetime)
  }

  get isOverlappingTimeSlot() {
    return this.overlapRecurrentDates.length !== 0
  }
  get isLocation() {
    console.log('isLocation', this.bookingForm.location.main_text)
    return this.bookingForm.location.main_text !== ''
  }

  get hasAvailableTimeSlot() {
    return (
      this.multiPackage.recurrentList
        .filter((timeslot) => moment() < moment(timeslot.start_datetime)) // เอาเฉพาะวันที่ upcoming
        .some((timeslot) => timeslot.isAvailable) ||
      (this.hasUsedTimeSlot && !this.multiPackage.isChangeMaid)
    )
  }

  /**
   * Return true if form is valid
   */
  async validateForm() {
    this.errors = []
    if (!this.hasAvailableTimeSlot) this.errors.push('please_select_at_least_available')
    if (!this.isSelectAtLeastOneTimeSlot) this.errors.push('please_select_time_slot')
    if (!this.isSelectAccommodation) this.errors.push('please_select_accomodation')
    if (!this.isSelectServices) this.errors.push('please_select_services')
    if (!this.isAgreePolicy) this.errors.push('please_agree_policy')
    if (this.isOverlappingTimeSlot) this.errors.push('error_overlapping_time_slot')
    // const isAvailableForAllDates = await this.validateTimeSlots()
    if (this.errors.length > 0) {
      console.log(this.errors)
      this.moveTo('#recurrent-timeslot')
      this.$root.$emit('bv::show::modal', 'error-form')
      return false
    }
    return this.errors.length === 0
  }

  // ============= End validate form data ===============

  requireAuthIfNotLoggedIn() {
    this.$root.$emit('bv::hide::modal', 'warning-remaining-credits')
    if (!this.isLoggedIn) {
      this.$root.$emit('bv::show::modal', 'require-login-modal')
      return false
    }
    return true
  }

  async mounted() {
    this.$store.dispatch('showFullContentLoader', true)
    // if (isUATEnv) {
    //   if (localStorage.multiPackage && !this.multiPackage.maid) {
    //     this.multiPackage = JSON.parse(localStorage.multiPackage);
    //   }
    // }
    console.log('Mounted', this.prevRoute)
    if (this.bookingReview.isUpdateBooking || this.isMyBookingMultiPackage) {
      this.isAgreePolicy = true
    }

    this.multiPackage.maid && this.fetchMaidDetail(this.multiPackage.maid.id)

    Object.assign(this.bookingForm, this.multiPackage)
    console.log('booking Form', this.bookingForm)

    // restore time slot
    this.$store.commit('calendar/setCurrentSelectedOnCalendar', null)
    await this.init()

    const preventFetchBooking = this.multiPackage.preventFetchBooking
    const { load, from } = this.$route.query
    console.log('preventFetchBooking', preventFetchBooking, this.$route.query)
    if (from === 'my-multi-package') {
      console.log('mounte', location)
      window.location.href = window.location.origin + window.location.pathname
      // alert("sss")
      // console.log("mounte",window.location.host + window.location.pathname);
    } else if (typeof load === 'undefined' && !preventFetchBooking) {
      // this.isUpdate = true
      await this.fetchBookingDetail()
      this.isFromMP = true

      // console.log("yes");
    }
    // console.log("this.bookingForm", this.bookingForm);
    if (this.bookingForm.maid && this.bookingForm.maid.id) {
      let isPublish = await ApiClient.maidDetail(this.bookingForm.maid.id)
      this.publish_status = isPublish.data.status
      console.log('this.publish_status', this.publish_status)
    }

    this.condoWarningDisplayed = true
    if (!this.showCondoWarningCheck) {
      this.condoWarningDisplayed = false
    }

    // console.log("setPreventInitTimeSlot: false");
    this.$store.dispatch('showFullContentLoader', false)
    this.$store.commit('booking/setPreventInitTimeSlot', false)
    this.$nextTick(() => {
      if (this.$refs.address === undefined) {
        window.location.reload()
      }
    })
  }

  checkPendingNewMaid() {
    const maid = JSON.parse(window.sessionStorage.getItem('maid_detail'))
    if (maid) {
      this.bookingForm.maid = maid
      this.updateBookingMultiPackage(this.bookingForm)
      this.$store.commit('booking/setCurrentBookingMultiPackage', {
        ...this.currentBookingMultiPackage,
        recurrentList: this.multiPackage.recurrentList,
      })
      window.sessionStorage.removeItem('maid_detail')
    }
  }

  async init() {
    const router = this.$store.state.router
    const enterByRefresh = router.from.name === null
    console.log('enterByRefresh', enterByRefresh)

    console.log('INIT')
    this.isRestoring = true
    const { isChangeMaidBefore, isChangeMaid, currentMaid } = this.multiPackage
    const isUserSelectNewMaid = isChangeMaidBefore !== isChangeMaid && !isChangeMaid
    console.log('fetchCalendarForTimeSlots')
    await this.fetchCalendarForTimeSlots(this.isLoggedIn)
    if (isUserSelectNewMaid) {
      console.log('createTimeSlotsFromBookingCalendar')
      this.createTimeSlotsFromBookingCalendar()
      await this.checkBookingCopy()
    } else if (isChangeMaid && !isChangeMaidBefore && !this.multiPackage.preventInitTimeSlot) {
      console.log('createTimeSlotsMultiPackage')
      this.createTimeSlotsMultiPackage()
    }

    // ดึงค่าบริการ
    await this.getPriceMultiPackage()
    this.isRestoring = false
  }
  @Watch('calendarForUpdateBookingPayload')
  // isAgreePolicyShouldTrue() {
  //   this.isAgreePolicy = true
  // }
  @Watch('recurrentTime')
  addRecurrentDate() {
    if (!this.recurrentTime) return
    // const newRecurrentDate = deepCloneObject(this.recurrentDate)
    const newRecurrentDate = {
      date: this.recurrentDate,
      time: this.recurrentTime,
    }
    // console.log("new",newRecurrentDate);
    this.bookingForm.recurrentDates.push({ ...newRecurrentDate })
    this.recurrentDate = null
    this.recurrentTime = null
    this.$refs.recurrentDateSelect.typeAheadPointer = -1
    this.$refs.recurrentDateSelect.selectedValue = null
    this.$refs.recurrentTimeSelect.typeAheadPointer = -1
    this.$refs.recurrentTimeSelect.selectedValue = null
  }

  deleteRecurrentDate(index) {
    this.deleteRecurrentDateIndex = index
    this.modalConfirmType = 'deleteRecurrentDate'
    this.$root.$emit('bv::show::modal', 'warning-reset-time-slots')
  }

  chooseMaid() {
    if (this.bookingForm.isAlreadyChangeMaid) {
      return
    }
    if (!this.multiPackage.canChangeMaid) {
      this.$root.$emit('bv::show::modal', 'warning-limit-change-maid')
      return
    }

    const hasSettedTimeSlots =
      this.multiPackage.recurrentList.filter((timeSlot) => timeSlot.start_datetime).length > 0
    if (hasSettedTimeSlots) {
      this.modalConfirmType = 'changeMaid'
      this.$root.$emit('bv::show::modal', 'warning-reset-time-slots')
      return
    }

    this.doChooseMaid()
  }

  doChooseMaid() {
    console.log('choosemaid')
    this.modalConfirmType = ''
    this.initTimeSlots()
    const route = this.$route
    this.bookingForm.recurrentDates = []
    this.updateBookingMultiPackage(this.bookingForm)
    this.$store.commit('booking/setPreventInitTimeSlot', false)

    let query = {
      type: 'multi-package',
      callback: route.path,
      from: route.query.from,
    }

    if (ENABLE_FILTER_MAID_BY_USER_LOCATION) {
      query = {
        ...query,
        location_latitude: get(this.bookingForm, 'location.latitude'),
        location_longitude: get(this.bookingForm, 'location.longitude'),
      }
    }

    if (this.isUserSelectBringEquipmentOption) {
      query = {
        ...query,
        bring_equipment: '1',
      }
    }

    if (this.bookingForm.services) {
      query = {
        ...query,
        services: this.bookingForm.services.value,
      }
    }

    this.$router.push({ name: 'SelectMaid', query })
  }

  // Return true if every recurrent dates is available
  // fix me (rename)
  async validateTimeSlots() {
    this.notAvailableTimeSlots = []
    const recurrentItems = this.multiPackage.recurrentList.filter((rc) => !!rc.start_datetime)
    let calendar = removeDuplicateTimeSlot(recurrentItems, this.multiPackage.historyRecurrentItems)
    calendar = removeDuplicateTimeSlot(calendar, this.calendarChangedMaid)
    const totalRecurrentDates = calendar.length
    const result = []

    for (let i = 0; i < totalRecurrentDates; i++) {
      const recurrentItem = calendar[i]
      result.push(recurrentItem.isAvailable)
      !recurrentItem.isAvailable && this.notAvailableTimeSlots.push(recurrentItem)
    }
    return false
  }

  async doBooking() {
    const temp_id = uuidv4()
    const bookingReview = {
      ...this.bookingForm,
      temp_id,
      // amount: this.totalPrice,
      amount: this.grandTotal, //
      type: 'multi-package',
      multi_times: this.bookingForm.package.value,
      calendar: this.calendarForNewBookingPayload,
      isUpdateBooking: false,
      calendarPassed: [],
    }
    this.setBookingReview(bookingReview)
    await this.updateBookingMultiPackage(this.bookingForm)

    if (this.isBookingMultiPackage) {
      this.$router.push({
        name: 'BookingReview',
        query: {
          temp_id,
        },
      })
    } else {
      this.doUpdateBooking()
    }
  }

  checkTimeCurrent() {
    const current_date = localStorage.getItem('currentDate') || new Date()
    const currentTime = new Date().getTime()
    const dateThreshold = 1000 * 60 * 60 // 1 hour in milliseconds
    const wrong_current_date =
      current_date && Math.abs(new Date(current_date).getTime() - currentTime) > dateThreshold
    this.dateTimeTimezone = this.getDateTimeTimezone()
    return wrong_current_date && !this.isAdmin
  }

  get isAdmin() {
    let adminToken = window.localStorage.getItem('admin_token')
    return this.isLoggedIn && adminToken && adminToken !== 'null'
  }

  async bookNow() {
    if (this.isBookingMultiPackage && !this.canBooking) {
      this.alertNotComplete()
      return
    }
    if (this.checkTimeCurrent()) {
      this.$root.$emit('bv::show::modal', 'warning-invalid-datetime')
      return
    }
    // console.log("this. location", this.bookingForm);
    const isBookingFormValid = await this.validateForm()

    this.bookingForm.recurrentList = this.multiPackage.recurrentList
    await this.updateBookingMultiPackage(this.bookingForm)

    if (!isBookingFormValid) return

    // Check has empty time slot
    const hasEmptyTimeSlot = this.multiPackage.recurrentList.some(
      (rc) => !rc.start_datetime || !rc.isAvailable
    )
    // if (hasEmptyTimeSlot && this.isBookingMultiPackage) {
    //   this.moveTo('#recurrent-timeslot')
    //   this.$root.$emit('bv::show::modal', 'warning-remaining-credits')
    //   return
    // }
    const unusedPassedTimeSlot = this.inquireDeletePassedTimeSlot()
    this.unusedPassedTimeSlot = unusedPassedTimeSlot
    if (unusedPassedTimeSlot.length !== 0) {
      console.log('Show Modal')
      this.moveTo('#recurrent-timeslot')
      this.$root.$emit('bv::show::modal', 'inquire-delete-timeslot-modal')
      return
    }

    await this.doBooking()
  }

  alertNotComplete() {
    this.isNotComplete = true
    const hasSomeRecurrentDate = this.multiPackage.recurrentList.some((rc) => !!rc.start_datetime) // must have at least 1 time slot
    const fieldsToCheck = [
      { id: 'not-complete-location', condition: !this.isLocation },
      { id: 'not-complete-equipment', condition: !this.bookingForm.equipment },
      { id: 'not-complete-services', condition: !this.isSelectServices },
      { id: 'not-complete-maid', condition: !this.bookingForm.maid },
      { id: 'not-complete-accommodation', condition: !this.isSelectAccommodation },
      {
        id: 'not-complete-duration-package',
        condition: !this.bookingForm.duration || !this.bookingForm.package,
      },
      {
        id: 'not-complete-time-slot',
        condition: !hasSomeRecurrentDate || !this.hasAvailableTimeSlot,
      },
      { id: 'not-complete-policy', condition: !this.isAgreePolicy },
    ]

    let scrollTo = 0

    setTimeout(() => {
      for (const { id, condition } of fieldsToCheck) {
        if (condition) {
          scrollTo = document.getElementById(id).getBoundingClientRect().top + window.scrollY
          break
        }
      }

      console.log('scrollTo', scrollTo)
      window.scrollTo({
        top: scrollTo - 100,
        behavior: 'smooth',
      })
    }, 100)
  }

  inquireDeletePassedTimeSlot() {
    const unusedPassedTimeSlot = this.multiPackage.recurrentList.filter(
      ({ end_datetime, status }) => {
        const isPassed = moment(end_datetime) < moment()
        return isPassed && status !== STATUS.approved
      }
    )
    return unusedPassedTimeSlot
  }

  async doUpdateBooking() {
    if (this.checkTimeCurrent()) {
      this.$root.$emit('bv::show::modal', 'warning-invalid-datetime')
      return
    }
    this.$root.$emit('bv::hide::modal', 'warning-remaining-credits')
    const isChangeMaid = this.multiPackage.isChangeMaid

    const bookingReview = {
      ...this.bookingForm,
      amount: 0,
      type: 'multi-package',
      multi_times: this.bookingForm.package.value,
      isUpdateBooking: true,
      calendar: this.calendarForUpdateBookingPayload,
      calendarPassed: isChangeMaid
        ? get(this.currentBookingMultiPackage, 'calendarPassed', [])
        : [],
    }
    this.setBookingReview(bookingReview)

    this.$store.commit('booking/setPreventFetchBooking', true)
    this.$store.commit('booking/setPreventInitTimeSlot', true)
    this.$router.push({
      name: 'BookingReview',
      query: {
        booking_id: this.bookingForm.id,
      },
    })
  }

  onSelectedDate(day) {
    console.log('day', day)
    const isDisabledDate = this.disabledGreyHighlight
      .concat(this.baseDisabledHighlight)
      .some(({ dates }) => {
        const today = isToday(moment(day.dateTime).toISOString())
        return moment(day.id).isSame(moment(dates), 'date') || today
      })

    if (!this.bookingForm.maid || isDisabledDate || moment(day.dateTime) < moment()) return
    const dateISOString = moment
      .tz(day.id + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss', 'UTC')
      .toISOString()
    this.selectedDate = dateISOString
    this.setCurrentSelectedOnCalendar(dateISOString)
  }

  async initTimeSlots() {
    if (!this.bookingForm.package) return
    if (this.isMyBookingMultiPackage) {
      let recurrentList = this.bookingForm.recurrentList.filter(
        (timeSlot) => timeSlot.status != 'APPROVED'
      )
      for (let index = 0; index < recurrentList.length; index++) {
        const item = recurrentList[index]
        const timeSlot = createEmptyTimeSlot(item.number)
        this.incrementRenderCount()
        this.updateRecurrentItem(timeSlot)
      }
      return
    }
    this.$store.dispatch('booking/createTimeSlotsMultiPackage').then((recurrentList) => {
      if (recurrentList) {
        this.bookingForm.recurrentList = clone(recurrentList)
      }
    })
  }

  initTimeSlotsFromPreviousTimeSlots() {
    if (!this.bookingForm.package) return
    const totalTimeSlots = this.bookingForm.package.value
    const timeSlots = this.multiPackage.recurrentList.slice(0, totalTimeSlots)
    const totalCurrentTimeSlots = timeSlots.length
    const totalRemainingTimeSlots = totalTimeSlots - totalCurrentTimeSlots
    if (totalRemainingTimeSlots > 0) {
      for (let i = totalCurrentTimeSlots; i < totalTimeSlots; i++) {
        timeSlots.push(createEmptyTimeSlot(i + 1))
      }
    }
    this.bookingForm.recurrentList = timeSlots
    this.setTimeSlotsMultiPackage(timeSlots)
    this.previousMultiTimesOptions = null
  }

  async checkUserLocationWithMaidRadius() {
    try {
      if (this.requireCheckMaidRadius) {
        this.isMaidOutOfRadius = await this.inquiryMaidOutOfRadius({
          latitude: get(this.bookingForm, 'location.latitude'),
          longitude: get(this.bookingForm, 'location.longitude'),
          maidId: get(this.bookingForm, 'maid.id'),
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  @Watch('bookingForm.additional')
  onAdditionalChanged(newVal) {
    this.updateBookingMultiPackage({ additional: [...newVal] })
  }

  @Watch('bookingForm.services')
  onServicesChanged(newVal) {
    this.updateBookingMultiPackage({ services: newVal })
  }
  @Watch('bookingForm.accommodation')
  onAccommodationChanged(newVal) {
    this.updateBookingMultiPackage({ accommodation: newVal })
  }

  @Watch('bookingForm.location', { deep: true })
  async onLocationChanged(newVal) {
    this.updateBookingMultiPackage({ location: { ...newVal } })
    await this.checkUserLocationWithMaidRadius()
  }

  @Watch('bookingForm.description')
  onDescriptionChanged(newVal) {
    clearTimeout(this.descriptionTimeout)
    this.descriptionTimeout = setTimeout(() => {
      this.updateBookingMultiPackage({ description: newVal })
    }, 1000)
  }

  @Watch('isLoggedIn')
  onIsLoggedInChanged() {
    if (this.isLoggedIn) {
      this.$root.$emit('bv::hide::modal', 'require-login-modal')
      if (this.callBackPathAfterWelcomePage && !this.isNewUser) {
        const path = this.callBackPathAfterWelcomePage
        this.$router.push({ path })
      } else {
        this.onMaidChanged()
      }
    } else {
      this.$root.$emit('bv::show::modal', 'require-login-modal')
    }
  }

  @Watch('bookingForm.recurrentDates', { deep: true })
  async onRecurrentDatesChanged(newVal, oldVal) {
    if (newVal.length === 0 && !this.multiPackage.preventInitTimeSlot) {
      console.log('INIT AGAINNNN')
      this.initTimeSlots()
      return
      // const hasTimeslots = this.multiPackage.recurrentList.some(rc => rc.start_datetime)
      // !hasTimeslots && this.initTimeSlots()
      // return
    }
    await this.generateTimeSlotsFromRecurrentDate()
  }

  async generateTimeSlotsFromRecurrentDate() {
    if (this.isRestoring) return
    if (this.bookingForm.package) {
      const { isChangeMaid, historyRecurrentItems } = this.multiPackage
      const bookingCalendarFromPreviousMaid = this.calendarChangedMaid

      // ถ้ามีการเปลี่ยนแม่บ้าน ให้เอา History timeslot มาเป็นค่าเริ่มต้น
      // หากไม่ได้เปลี่ยน ให้เริ่มต้นด้วยลิสว่าง []
      let recurrentList = isChangeMaid
        ? [...bookingCalendarFromPreviousMaid, ...historyRecurrentItems].map((booking, index) => ({
            ...booking,
            number: index + 1,
          }))
        : []

      // จำนวนครั้งที่แม่บ้านต้องทำงาน
      const packageCount = this.bookingForm.package.value
      console.log(packageCount)
      for (let i = 0; i < packageCount; i++) {
        this.bookingForm.recurrentDates.forEach((recurrentDate) => {
          const { date, time } = recurrentDate // date option & time option
          const startDate = createMomentByRepeatDay({
            repeatDay: 7 * i + date.value,
            hour: time.startHour,
            minute: time.startMinute,
          })
          const endDate = createMomentByRepeatDay({
            repeatDay: 7 * i + date.value,
            hour: time.endHour,
            minute: time.endMinute,
          })
          if (startDate.isAfter(minimumBookingMoment())) {
            recurrentList.push({
              date: startDate,
              start_datetime: startDate.toISOString(),
              end_datetime: endDate.toISOString(),
              time,
            })
          } else {
            const startDate = createMomentByRepeatDay({
              repeatDay: 7 * (i + packageCount) + date.value,
              hour: time.startHour,
              minute: time.startMinute,
            })
            const endDate = createMomentByRepeatDay({
              repeatDay: 7 * (i + packageCount) + date.value,
              hour: time.endHour,
              minute: time.endMinute,
            })
            recurrentList.push({
              date: startDate,
              start_datetime: startDate.toISOString(),
              end_datetime: endDate.toISOString(),
              time,
            })
          }
        })
      }
      // ตัดให้เหลือเท่าจำนวนวันของแพ็กเกจ
      console.log(recurrentList, 'yay')
      recurrentList = recurrentList.sort((a, b) => {
        return moment(a.start_datetime) - moment(b.start_datetime)
      })
      if (this.isMyBookingMultiPackage) {
        let bookingCalendars = this.multiPackage.recurrentList.filter(
          (timeSlot) => timeSlot.status == 'APPROVED'
        )
        recurrentList = recurrentList.filter((timeSlot) => {
          return !bookingCalendars.find((ts) =>
            moment(ts.start_datetime).isSame(moment(timeSlot.start_datetime), 'date')
          )
        })
        recurrentList = recurrentList.map((timeSlot, index) => ({
          ...timeSlot,
          status: 'UPDATE_AWAITING',
        }))
        recurrentList = [
          ...bookingCalendars,
          ...recurrentList.slice(0, this.bookingForm.package.value - bookingCalendars.length),
        ]
      } else {
        recurrentList = recurrentList.slice(0, this.bookingForm.package.value)
      }
      recurrentList = recurrentList.map((timeSlot, index) => ({
        number: index + 1,
        ...timeSlot,
      }))
      // this.setTimeSlotsMultiPackage(recurrentList)

      // console.log("fetch calendar",this.isLoggedIn)
      this.$store.commit('booking/setTimeSlotsMultiPackage', recurrentList)
      await this.fetchCalendarForTimeSlots(this.isLoggedIn)

      this.bookingForm.recurrentList = [...recurrentList.slice(0, this.bookingForm.package.value)]
    }
  }

  @Watch('bookingForm.package', { deep: true })
  async onPackageChanged(newVal, oldVal) {
    console.log(newVal, oldVal)
    // this.updateBookingMultiPackage(this.bookingForm);
    if (!this.isBookingMultiPackage) return
    if (!!this.bookingForm.duration) {
      await this.getPriceMultiPackage()
    }

    // เข็คว่ามี Timeslot ที่มีการเลือกวันเวลาแล้วหรือยัง
    const recurrentList = this.multiPackage.recurrentList.filter((rc) => rc.start_datetime)
    const totalRecurrentList = recurrentList.length
    const hasSomeRecurrentDate = totalRecurrentList > 0

    // กรณีที่ค่าใหม่มาจากการ Restore
    if (
      hasSomeRecurrentDate &&
      this.previousMultiTimesOptions !== null &&
      this.previousMultiTimesOptions.value === newVal.value
    ) {
      this.previousMultiTimesOptions = null
      await this.getPriceMultiPackage()
      return
    }

    // กรณีที่มีวันเวลาที่เลือกไว้แล้ว และ มีการลดจำนวนครั้งของ Package
    // ทำให้ต้องมีการ Confirm การตัด timeslot ออก
    if (hasSomeRecurrentDate && oldVal && newVal.value < oldVal.value) {
      // Confirmation is required
      if (newVal.value < totalRecurrentList) {
        // Save ค่าก่อนหน้า ในกรณีที่ต้อง Restore Value
        this.previousMultiTimesOptions = oldVal
        // Set ค่าลง confirm modal
        this.modalConfirmType = 'reduceTimeSlots'
        return
      } else {
        this.initTimeSlotsFromPreviousTimeSlots()
        return
      }
    }

    // กรณีที่มีวันเวลาที่เลือกไว้แล้ว และ มีการเพิ่มจำนวนครั้งของ Package
    this.previousMultiTimesOptions = null // reset
    const isSelectRecurrentDate = this.bookingForm.recurrentDates.length > 0
    if (hasSomeRecurrentDate && oldVal && newVal.value > oldVal.value && isSelectRecurrentDate) {
      await this.generateTimeSlotsFromRecurrentDate() // สร้าง Timeslot จาก Recurrent date
      return
    }

    if (!oldVal && !hasSomeRecurrentDate) {
      // create empty timeslot
      await this.updateBookingMultiPackage(this.bookingForm)
      this.initTimeSlots()
    } else {
      this.initTimeSlotsFromPreviousTimeSlots()
    }
  }

  @Watch('bookingForm.duration')
  async onDurationChanged(newVal, oldVal) {
    console.log('Duration Changed', newVal)
    this.updateBookingMultiPackage(this.bookingForm)
    // console.log("");
    // this.initTimeSlots();
    const hasSomeRecurrentDate = this.bookingForm.recurrentList.some(
      (rc) => rc.start_datetime != null
    )
    if (hasSomeRecurrentDate && !this.isRestoring) {
      // ในกรณีที่ Restore กลับไปค่าเดิม จะไม่ต้องทำอะไร
      if (
        this.previousDurationOptions !== null &&
        this.previousDurationOptions.value === newVal.value
      ) {
        this.previousDurationOptions = null
        await this.getPriceMultiPackage()
        return
      }
      this.previousDurationOptions = { ...oldVal }
      this.modalConfirmType = 'clearForm'
      return
    }
    await this.getPriceMultiPackage()
  }

  @Watch('bookingForm.equipment', { deep: true })
  async onEquipmentOptionChange() {
    await this.getPriceMultiPackage()
  }

  async getPriceMultiPackage() {
    if (!isEmpty(this.bookingForm.package)) {
      try {
        const result = await ApiClient.getBookingPrice({
          duration: get(this.bookingForm, 'duration.value'),
          multi_times: get(this.bookingForm, 'package.value'),
          type: 'MULTI_PACKAGE',
          bring_equipment: this.isUserSelectBringEquipmentOption,
        })
        this.totalPrice = get(result, 'data.total_price')
        console.log('data total proce', this.totalPrice)
      } catch (e) {
        console.log(e.response)
      }
    }
  }

  get recurrentTimeOptions() {
    if (!this.bookingForm.duration) return []
    const totalHours = this.bookingForm.duration.value
    if (!totalHours) return []
    const durations = []
    for (let i = 6; i <= 21 - totalHours; i++) {
      const startTime = this.$momentTZ().hour(i).minute(0).millisecond(0)
      const endTime = this.$momentTZ()
        .hour(i + totalHours)
        .minute(0)
        .millisecond(0)

      // xx:00 - xx:00
      durations.push({
        text: `${startTime.format('H:mm')} - ${endTime.format('H:mm')}`,
        startHour: startTime.hour(),
        startMinute: 0,
        endHour: endTime.hour(),
        endMinute: 0,
      })

      // xx:30 - xx:30
      if (i + totalHours !== 21) {
        const startTime2 = this.$momentTZ().hour(i).minute(30).millisecond(0)
        const endTime2 = this.$momentTZ()
          .hour(i + totalHours)
          .minute(30)
          .millisecond(0)
        durations.push({
          text: `${startTime2.format('H:mm')} - ${endTime2.format('H:mm')}`,
          startHour: startTime2.hour(),
          startMinute: startTime2.minutes(),
          endHour: endTime2.hour(),
          endMinute: endTime2.minute(),
        })
      }
    }
    console.log('durations', durations)
    return durations
  }

  async confirmAction() {
    switch (this.modalConfirmType) {
      case 'reduceTimeSlots':
        await this.reduceTimeSlots()
        break
      case 'deleteRecurrentDate':
        this.bookingForm.recurrentDates.splice(this.deleteRecurrentDateIndex, 1)
        this.deleteRecurrentDateIndex = null
        break
      case 'clearForm':
        this.bookingForm.recurrentDates = []
        break
      case 'changeMaid':
        this.doChooseMaid()
        break
      default:
        break
    }
    this.modalConfirmType = ''
    this.$root.$emit('bv::hide::modal', 'warning-reset-time-slots')
    await this.getPriceMultiPackage()
  }

  async confirmDeletePassedTimeSlot() {
    const timeslot = this.multiPackage.recurrentList.filter(({ end_datetime, status }) => {
      if (!end_datetime) return false
      const isPassed = moment(end_datetime) < moment()
      return (isPassed && status === STATUS.approved) || !isPassed
    })
    await this.$store.dispatch('booking/fillTimeSlot', timeslot)
    this.onCloseInquireDeleteModal()
    this.doUpdateBooking()
  }

  onCloseInquireDeleteModal() {
    this.$root.$emit('bv::hide::modal', 'inquire-delete-timeslot-modal')
    this.moveTo('#recurrent-timeslot')
    this.unusedPassedTimeSlot = []
  }

  restorePreviousMultiTime() {
    if (this.modalConfirmType === 'reduceTimeSlots') {
      this.bookingForm.package = { ...this.previousMultiTimesOptions }
    }
    if (this.modalConfirmType === 'clearForm') {
      this.bookingForm.duration = { ...this.previousDurationOptions }
    }
    this.modalConfirmType = ''
  }

  async reduceTimeSlots() {
    this.initTimeSlotsFromPreviousTimeSlots()
    if (!!this.bookingForm.duration) {
      await this.getPriceMultiPackage()
    }
  }

  @Watch('modalConfirmType')
  onModalTypeChanged() {
    console.log('modalConfirmType changed')
    if (this.modalConfirmType && !this.isFetchingBookingDetail) {
      this.$root.$emit('bv::show::modal', 'warning-reset-time-slots') // confirmation modal
    }
  }

  @Watch('bookingForm.maid', { deep: true })
  async onMaidChanged() {
    if (!this.bookingForm.maid) return
    const now = new Date()
    this.getMaidBookingTotal({
      id: this.bookingForm.maid.id,
      month: now.getMonth() + 1,
      year: now.getFullYear(),
    })
    await this.checkUserLocationWithMaidRadius()
  }

  async onMonthChanged(page) {
    if (this.bookingForm.maid)
      await this.getMaidBookingTotal({
        id: this.bookingForm.maid.id,
        ...page,
        isMe: this.isLoggedIn,
      })
  }

  /** Get maid calendar booking from API */
  async getMaidBookingTotal(payload) {
    console.log('getMaidBookingTotal', payload)
    const maidCalendar = this.isLoggedIn
      ? await this.fetchMaidCalendarAllMonth(payload)
      : await this.fetchMaidCalendarAllMonthPublic(payload)
    if (!maidCalendar) return
    this.maidDisabledDates = getDisabledDateFromTimeSlot(maidCalendar)
  }

  openServiceModal(type) {
    if (type === 'Terms') {
      this.$router.push({ name: 'Terms' })
      // window.open(window.location.origin + '/terms')
    } else {
      this.$root.$emit('bv::show::modal', 'agreement-modal')
    }
    this.isAgreePolicy = !this.isAgreePolicy // prevent check by clicling at the text
  }

  doSignIn() {
    this.setCallBackPathAfterWelcomePage('/booking-review')
    this.$root.$emit('bv::hide::modal', 'require-login-modal')
    this.signIn()
  }

  // ================= Calendar Highlight ====================
  get calendarAttrs() {
    return [
      this.todayHighlight,
      this.currentSelectDateHighlight,
      ...this.popovers,
      ...this.grayDots,
      ...this.whiteDots,
      ...this.baseDisabledHighlight,
      ...this.disabledGreyHighlight,
      ...this.greenHighlight,
      ...this.redHighlight,
    ]
  }

  get minDate() {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    return date
  }

  get todayHighlight() {
    return {
      key: 'today',
      content: 'blue',
      dates: new Date(...this.$momentTZ().toArray()),
    }
  }

  get currentSelectDateHighlight() {
    return {
      key: 'selectedDay',
      bar: true,
      dates: this.selectedDate ? this.selectedDate : null,
    }
  }

  get redHighlight() {
    if (this.isInitializeTimeSlot) return []
    return this.multiPackage.recurrentList
      .filter((timeSlot) => {
        return (
          !timeSlot.isAvailable &&
          !!timeSlot.start_datetime &&
          timeSlot.isAvailable !== null &&
          !isDateInTimeSlots(timeSlot.start_datetime, [...this.baseDisabledHighlight])
        )
      })
      .map((timeSlot) => ({
        key: 'busy',
        highlight: 'red',
        content: 'white',
        dates: new Date(...this.$momentTZ(timeSlot.start_datetime).toArray()), //new Date(...this.$momentTZ(timeSlot.start_datetime).toArray())
      }))
  }

  get greenHighlight() {
    if (this.isInitializeTimeSlot) return []
    let recurrentList = null
    recurrentList = this.multiPackage.recurrentList
    return recurrentList
      .filter((timeSlot) => {
        return (
          timeSlot.isAvailable &&
          timeSlot.start_datetime &&
          timeSlot.isAvailable !== null &&
          !isDateInTimeSlots(timeSlot.start_datetime, [
            ...this.baseDisabledHighlight,
            ...this.disabledGreyHighlight,
          ])
        )
      })
      .map((timeSlot) => ({
        key: 'busy',
        highlight: 'green',
        content: 'white',
        dates: new Date(...this.$momentTZ(timeSlot.start_datetime).toArray()),
      }))
  }

  get grayDots() {
    const timeSlots = this.multiPackage.recurrentList.filter(
      (timeSlot) => !!timeSlot.start_datetime
    )
    const calendar = this.calendarTotalHours.filter((d) => {
      return timeSlots.every(
        (timeSlot) => !moment(d.date).isSame(moment(timeSlot.start_datetime), 'date')
      )
    })

    const firstDot = {
      key: 'firstDot',
      dot: { class: 'custom-dot-gray' },
      dates: [],
    }
    const secondDot = {
      key: 'secondDot',
      dot: { class: 'custom-dot-gray' },
      dates: [],
    }
    const thirdDot = {
      key: 'thirdDot',
      dot: { class: 'custom-dot-gray' },
      dates: [],
    }
    calendar.forEach((d) => {
      const newDate = d.date
      const isDisabledDate = isDateInTimeSlots(d.date, [
        ...this.maidDisabledDates,
        ...this.baseDisabledHighlight,
      ])
      if (parseInt(d.total_hours) >= 2 && !isDisabledDate) firstDot.dates.push(newDate)
      if (parseInt(d.total_hours) >= 4 && !isDisabledDate) secondDot.dates.push(newDate)
      if (parseInt(d.total_hours) >= 6 && !isDisabledDate) thirdDot.dates.push(newDate)
    })
    return this.multiPackage.maid ? [firstDot, secondDot, thirdDot] : []
  }

  get whiteDots() {
    const timeSlots = this.multiPackage.recurrentList.filter(
      (timeSlot) => !!timeSlot.start_datetime
    )
    const calendar = this.calendarTotalHours.filter((d) => {
      return timeSlots.some((timeSlot) =>
        moment(d.date).isSame(moment(timeSlot.start_datetime), 'date')
      )
    })
    const firstDot = {
      key: 'firstWhiteDot',
      dot: { class: 'custom-dot-white' },
      dates: [],
    }
    const secondDot = {
      key: 'secondWhiteDot',
      dot: { class: 'custom-dot-white' },
      dates: [],
    }
    const thirdDot = {
      key: 'thirdWhiteDot',
      dot: { class: 'custom-dot-white' },
      dates: [],
    }
    calendar.forEach((d) => {
      const newDate = d.date
      const isDisabledDate = isDateInTimeSlots(d.date, [
        ...this.maidDisabledDates,
        ...this.baseDisabledHighlight,
      ])
      if (parseInt(d.total_hours) >= 2 && !isDisabledDate) firstDot.dates.push(newDate)
      if (parseInt(d.total_hours) >= 4 && !isDisabledDate) secondDot.dates.push(newDate)
      if (parseInt(d.total_hours) >= 6 && !isDisabledDate) thirdDot.dates.push(newDate)
    })
    return [firstDot, secondDot, thirdDot]
  }

  /**
   * คืนค่าอาเรย์ของวันที่ต้อง highlight (เทา)
   */
  get disabledGreyHighlight() {
    // console.log(this.maidDisabledDates);
    //  console.log("🚀 --------------------------------------------------------------------------------------------------------------------------------------------")
    // console.log("🚀 ~ file: BookMultiPackage.vue ~ line 1681 ~ BookingMultiPackage ~ getdisabledGreyHighlight ~ this.maidDisabledDates", this.maidDisabledDates)
    // console.log("🚀 --------------------------------------------------------------------------------------------------------------------------------------------")
    const baseTimeSlots = this.maidDisabledDates.filter((slot) => {
      // เอาเฉพาะวันที่ไม่มีใน recurrent list
      return !this.multiPackage.recurrentList.some((s) =>
        moment(s.start_datetime).isSame(moment(slot.start_datetime), 'date')
      )
    })
    return baseTimeSlots.map((d) => ({
      key: 'busy',
      highlight: 'gray',
      content: isToday(d.start_datetime) ? 'blue' : 'gray',
      dates: this.$momentTZ(d.start_datetime).locale(this.$i18n.locale).format(),
    }))
  }

 get popovers() {
    const baseTimeSlots = this.maidDisabledDates.filter((slot) => {
      // เอาเฉพาะวันที่ไม่มีใน recurrent list
      return !this.multiPackage.recurrentList.some((s) =>
        moment(s.start_datetime).isSame(moment(slot.start_datetime), 'date')
      )
    })
    return baseTimeSlots.map((d) => ({
      key: 'popover',
      popover: {
        label: this.$t('form_label.not_available'),
        placement : 'auto',
      },
      dates: this.$momentTZ(d.start_datetime).locale(this.$i18n.locale).format(),
    }))
  }

  get shouldShowCalendarDetail() {
    console.log('this.maidAvailableTimeSlots', this.maidAvailableTimeSlots)
    return !!this.selectedDate && !!this.bookingForm.maid && this.maidAvailableTimeSlots.length > 0
  }
  get isUpdate() {
    let isTrue = false
    // console.log("router",this.$route);
    if (this.$route.params.id) {
      isTrue = true
    }
    return isTrue
  }
  get IronText() {
    if (this.bookingForm.services !== 'CLEANING') {
      if (this.bookingForm.maid.service_types.length > 1) {
        if (this.bookingForm.maid.service_types[0]) {
          return true
        }
        if (this.bookingForm.maid.service_types[1]) {
          return true
        }
      } else {
        if (this.bookingForm.maid.service_types[0]) {
          return true
        }
      }
      //  this.bookingForm.maid.services_type
    }

    return false
  }
  get shoudShowBookingButton() {
    if (this.isBookingMultiPackage) return true
    if (this.isMultiPackageCompleted) return true
    if (!this.isCanceledByCustomer && !this.isMaidRejectEntirePackage) return true
    if (this.multiPackage.isChangeMaid) return true
    return true
  }

  get bookingId() {
    return this.$route.params.id
  }

  // Booking detail
  async fetchBookingDetail() {
    this.$store.dispatch('showFullContentLoader', true)

    const startTimer = moment()

    const params = this.$route.params // fix me
    if (params.id) {
      this.isFetchingBookingDetail = true
      const result = await ApiClient.bookingDetail(params.id)
      this.$store.commit('calendar/setCurrentBookingId', parseInt(params.id))
      if (result.data) {
        // this.isCheckboxRender = false
        this.isAgreePolicy = true
        const bookingDetail = result.data
        const services = Constant.SERVICES.find(
          (option) => option.value === bookingDetail.services.join('_')
        )
        const accommodation = Constant.ACCOMMODATION.find(
          (option) => option.value === bookingDetail.accommodation
        )
        const duration = Constant.DURATION.find((option) => option.value === bookingDetail.duration)

        const accommodationText = accommodation
          ? this.$t(`accommodation.${accommodation.text}`)
          : null

        const serviceText = services
          ? this.$t(`services.${services.text}`)
          : this.$t(`services.cleaning_ironing`)
        const serviceValue = services ? services.value : 'cleaning_ironing'

        this.bookingForm.id = bookingDetail.id
        this.bookingForm.equipment = this.getDefaultEquipmentOption(bookingDetail)
        this.bookingForm.accommodation = {
          ...accommodation,
          text: accommodationText,
        }
        this.bookingForm.services = {
          ...services,
          text: serviceText,
          value: serviceValue,
        }
        this.bookingForm.maid = bookingDetail.maid
        this.bookingForm.currentMaid = bookingDetail.maid
        await this.$store.dispatch('maid/addHiddenMaid', bookingDetail.maid_id)
        await this.fetchMaidDetail(bookingDetail.maid_id)
        this.bookingForm.additional = bookingDetail.additional_infos
        this.bookingForm.method = 1
        this.bookingForm.description = bookingDetail.description
        this.bookingForm.location = {
          description: bookingDetail.location_secondary,
          secondary_text: bookingDetail.location_secondary,
          main_text: bookingDetail.location_name,
          latitude: bookingDetail.location_latitude,
          longitude: bookingDetail.location_longitude,
          place_id: bookingDetail.location_place_id,
          additional_details: bookingDetail.location_additional_details,
        }
        this.bookingForm.location_additional_details = bookingDetail.location_additional_details

        this.bookingForm.duration = duration
        this.bookingForm.package = {
          text: `${bookingDetail.multi_times} Times`,
          value: bookingDetail.multi_times,
        }
        this.bookingForm.bookedTimes = bookingDetail.booked_times
        this.bookingForm.notBookedTimes = bookingDetail.not_booked_times
        this.bookingForm.payment = bookingDetail.payment
        this.bookingForm.payment_total_price = bookingDetail.payment_total_price
        this.bookingForm.total_price = bookingDetail.total_price

        const historyRecurrentItems = []
        const booking_calendars = sortTimeSlots(bookingDetail.booking_calendars)
        //this.timeDetailForSameDay = bookingDetail.booking_calendars[0].start_datetime
        console.log('bookingDetail', bookingDetail)
        for await (let bookingObj of booking_calendars) {
          const recurrentItem = createTimeSlotItem({
            ...bookingObj,
            booking_id: this.bookingId,
            maid_id: bookingDetail.maid_id,
          })
          if (
            moment(recurrentItem.start_datetime).diff(this.momentCurrentDate, 'seconds') <
              60 * 60 * 3 &&
            recurrentItem.status === STATUS.approved
          ) {
            historyRecurrentItems.push(recurrentItem)
          }
        }
        // booking_calendars.forEach((bookingObj) => {
        //   console.log("bookingObj", bookingObj);
        //   const recurrentItem = createTimeSlotItem({
        //     ...bookingObj,
        //     booking_id: this.bookingId,
        //     maid_id: bookingDetail.maid_id,
        //   });
        //   if (
        //     moment(recurrentItem.start_datetime).diff(moment(), "seconds") <
        //       60 * 60 * 24 &&
        //     recurrentItem.status === STATUS.approved
        //   ) {
        //     historyRecurrentItems.push(recurrentItem);
        //   }
        // });

        this.bookingForm.historyRecurrentItems = [...historyRecurrentItems]
        console.log('this.bookingForm.historyRecurrentItems', [...historyRecurrentItems])

        this.updateBookingMultiPackage({
          historyRecurrentItems: [...historyRecurrentItems],
          bookedTimes: bookingDetail.booked_times,
          notBookedTimes: bookingDetail.not_booked_times,
          bookingCalendars: bookingDetail.booking_calendars,
          bookingChangedMaid: bookingDetail.booking_changed_maid,
          package: {
            text: `${bookingDetail.multi_times} Times`,
            value: bookingDetail.multi_times,
          },
        })
        // await setTimeout(async () => {
        await this.fetchMaidDetailForBookingChangedMaid()
        this.createTimeSlotsFromBookingCalendar()
        await this.fetchCalendarForTimeSlots(this.isLoggedIn)
        this.bookingForm.bookingCalendars = this.multiPackage.bookingCalendars
        this.bookingForm.bookingChangedMaid = this.multiPackage.bookingChangedMaid
        this.bookingForm.isAlreadyChangeMaid = bookingDetail.changed_maid
        this.bookingForm.recurrentList = this.multiPackage.recurrentList
        this.bookingForm.payment_status = bookingDetail.payment_status || null
        await this.updateBookingMultiPackage(this.bookingForm)
        await this.checkBookingCopy()
        await this.$store.commit('booking/setCurrentBookingMultiPackage', {
          location: {
            description: bookingDetail.location_secondary,
            secondary_text: bookingDetail.location_secondary,
            main_text: bookingDetail.location_name,
            latitude: bookingDetail.location_latitude,
            longitude: bookingDetail.location_longitude,
          },
          additional: bookingDetail.additional_infos,
          description: bookingDetail.description,
          services: { ...services, text: serviceText, value: serviceValue },
          accommodation: {
            ...accommodation,
            text: accommodationText,
          },
          calendarPassed: [],
          recurrentList: this.multiPackage.recurrentList,
          multi_times: bookingDetail.multi_times,
          payment_total_price: bookingDetail.payment_total_price,
        })
        this.$store.dispatch('booking/calculateCalendarPassed')
        console.log('this.is', this.multiPackage)
        this.totalPrice = 0
        // }, 500);
      }
    }
    this.isFetchingBookingDetail = false

    console.log('End Timer', moment() - startTimer)
    this.$store.dispatch('showFullContentLoader', false)
  }

  async fetchMaidDetail(maidID) {
    this.isFetchingMaid = true
    try {
      const result = await ApiClient.maidDetail(maidID)
      this.bookingForm.maid = result.data
      this.setMaidMultiPackage(result.data)
      this.$store.commit('maid/addMaidDetail', result.data)
    } catch (e) {
      console.log(e)
    }
    this.isFetchingMaid = false
  }

  get currentRoute() {
    return this.$router.currentRoute
  }

  get isBookingMultiPackage() {
    return this.route === 'BookingMultiPackage'
  }

  get isMyBookingMultiPackage() {
    return this.route === 'MyBookingMultiPackage'
  }

  get isCanceledByCustomer() {
    return this.currentBookingMultiPackage.recurrentList.some(
      (booking) => booking.status === STATUS.canceledByCustomer
    )
  }
  get isReview() {
    return this.multiPackage.payment_status === 'BANK_TRANSFER_REVIEWING'
  }

  get isMultiPackageCompleted() {
    const isAllPassed = this.currentBookingMultiPackage.recurrentList.every(
      (booking) => this.momentCurrentDate > moment(booking.end_datetime)
    )

    const isAllApproved = this.currentBookingMultiPackage.recurrentList.every(
      ({ status }) => status === STATUS.approved
    )

    const totalApproved = this.currentBookingMultiPackage.recurrentList.filter(
      ({ status, start_datetime }) => start_datetime && status === STATUS.approved
    ).length

    const isAllCreditsUsed =
      this.currentBookingMultiPackage.multi_times === totalApproved && totalApproved > 0

    return isAllPassed && isAllApproved && isAllCreditsUsed
  }

  get isMaidRejectEntirePackage() {
    return this.currentBookingMultiPackage.recurrentList
      .filter((booking) => booking.start_datetime)
      .every((booking) => booking.status === STATUS.canceledByMaid)
  }
  get isTimeoutOfAppproval() {
    const isTimeOut = this.currentBookingMultiPackage.recurrentList.filter((booking) => {
      return booking.start_datetime && moment().diff(moment(booking.start_datetime), 'days') > 1
    })
    return isTimeOut.length >= 1 ? true : false
  }

  get hasCancelledByMaid() {
    return this.currentBookingMultiPackage.recurrentList
      .filter((booking) => booking.start_datetime)
      .some((booking) => booking.status === STATUS.canceledByMaid)
  }

  get hasApprovedTimeSlot() {
    return this.currentBookingMultiPackage.recurrentList.some(
      ({ status }) => status === STATUS.approved
    )
  }

  get hasServiceCompleteTimeSlot() {
    return this.currentBookingMultiPackage.recurrentList.some(
      ({ status, end_datetime }) => status === STATUS.approved && moment() > moment(end_datetime)
    )
  }

  get badgeLabel() {
    console.log('this.multiPackage.payment_status', this.multiPackage.payment_status)
    if (this.isReview) {
      return this.$t('status.bank_transfer_reviewing')
    }
    if (this.isMultiPackageCompleted) return this.$t('status.multi_package_completed')
    if (this.hasServiceCompleteTimeSlot) return this.$t('status.assignment_confirmed')
    if (this.isMaidRejectEntirePackage || this.hasCancelledByMaid || this.isTimeoutOfAppproval)
      return this.$t('status.assignment_unsuccessful')
    if (this.isMaidRejectEntirePackage) return this.$t('status.assignment_unsuccessful')
    if (this.hasApprovedTimeSlot) return this.$t('status.assignment_confirmed')
    if (this.isCanceledByCustomer) return ``
    return this.$t('status.payment_confirmed')
  }

  get tooltipText() {
    if (this.isMultiPackageCompleted) return ``
    if (this.multiPackage.payment_status === 'BANK_TRANSFER_REVIEWING') {
      return this.$t('status.your_bank_transfer_is_under_review')
    }
    if (this.hasServiceCompleteTimeSlot)
      return this.$t('status.maid_has_accepted_your_multi_package_assignment')
    if (this.isMaidRejectEntirePackage || this.hasCancelledByMaid)
      return this.$t('form_label.sorry_maid_cannot_accept_mp')
    return this.hasApprovedTimeSlot
      ? this.$t('status.maid_has_accepted_your_multi_package_assignment')
      : this.$t('status.waiting_for_maids_confirmation')
  }

  get badgeVaraint() {
    if (this.isReview) return `light`
    if (this.isMultiPackageCompleted) return `dark`
    if (this.hasServiceCompleteTimeSlot) return `warning`
    if (
      this.isMaidRejectEntirePackage ||
      this.isCanceledByCustomer ||
      this.hasCancelledByMaid ||
      this.isTimeoutOfAppproval
    )
      return `danger`
    return this.hasApprovedTimeSlot ? `warning` : `primary`
  }

  get route() {
    return this.$route.name
  }

  get momentCurrentDate() {
    let current_date = localStorage.getItem('currentDate') || new Date()
    let moment_date = moment(current_date)
    let adminToken = window.localStorage.getItem('admin_token')
    if (adminToken && adminToken !== 'null') {
      moment_date = moment()
    }
    return moment_date
  }

  get cancelable() {
    if (
      this.isBookingMultiPackage ||
      this.isFetchingBookingDetail ||
      this.multiPackage.isChangeMaid ||
      this.isCanceledByCustomer
    ) {
      return false
    }

    // Cancellation rules for Booking MP
    // After changing the maids, cancel button is gone and only update button remains,
    // even though maid has not accepted MP AND the booking is more than 24 hrs.
    // Please remember that
    //   if (a) No maid accepted MP, user can cancel the MP at any time regardless of 24 hr rule,
    //   (b) Maid accepted but no credit has been used yet AND no credit is within 24 hr before appointment,
    //   the user should be able to cancel. https://photos.app.goo.gl/xBz2NSpnKeBjbaD98

    // ยกเลิก Booking ได้ถ้า
    // 1. ยกเลิกโดยแม่บ้าน และ ไม่มี timeslot ไหนถูกใช้
    // 2. หรือ ไม่มี timeslot ที่ถูกใช้ + ไม่มีที่จะเกิดขึ้นในอีก 24 ชม

    return (
      !this.hasUpcomingBooking || // Case a
      (!this.hasUsedTimeSlot && !this.hasUpcomingBooking) || // Case b
      (this.isMaidRejectEntirePackage && !this.hasUsedTimeSlot)
    )
  }

  get sortedTimeSlots() {
    // ตัดเครดิตที่ไม่ถูกเลิอกทิ้ง
    const bookedTimeSlot = this.multiPackage.recurrentList.filter(
      (booking) => !!booking.start_datetime
    )
    const sortedTimeSlot = sortTimeSlots(bookedTimeSlot)
    return sortedTimeSlot
  }

  get isAllUnused() {
    return this.sortedTimeSlots.every((booking) => {
      return moment(booking.start_datetime) > moment()
    })
  }

  get hasUsedTimeSlot() {
    const sortedTimeSlot = this.sortedTimeSlots
    return sortedTimeSlot.some((booking) => {
      return moment() > moment(booking.end_datetime) && STATUS.approved === booking.status
    })
  }

  get hasUpcomingBooking() {
    let sortedTimeSlot = this.sortedTimeSlots

    console.log('sortedTimeSlot', sortedTimeSlot)
    if (isEmpty(sortedTimeSlot)) return false

    // console.log(
    //   '🚀 ~ file: BookMultiPackage.vue ~ BookingMultiPackage ~ gethasUpcomingBooking ~ moment(sortedTimeSlot[0].start_datetime).diff(moment(), ',
    //   moment(sortedTimeSlot[0].start_datetime).diff(moment(), 'second'),
    //   60 * 60 * 24
    // )
    // console.log(
    //   "condition hasUpcoming",
    //   this.timeDetailForSameDay,
    //   moment(this.timeDetailForSameDay).diff(moment(), "seconds")
    // );

    return (
      moment(sortedTimeSlot[0].start_datetime).diff(this.momentCurrentDate, 'seconds') <
        60 * 60 * 3 && STATUS.approved === sortedTimeSlot[0].status
    )
    // return (
    //   moment(this.timeDetailForSameDay).diff(moment(), "seconds") < 60 * 60 * 25
    // );
    // return sortedTimeSlot.some((booking) => {

    //   return (
    //     moment(booking.start_datetime).diff(moment(), "hour") > 3 &&
    //     STATUS.approved === booking.status
    //   );
    //   // return (
    //   //   moment(booking.start_datetime).diff(moment(), "seconds") <=
    //   //     60 * 60 * 24 && STATUS.approved === booking.status
    //   // );
    // });
  }

  toggleCancelModal() {
    if (this.checkTimeCurrent()) {
      this.$root.$emit('bv::show::modal', 'warning-invalid-datetime')
      return
    }
    this.$root.$emit('bv::show::modal', 'cancel-booking-modal')
  }

  async doCancelBooking() {
    try {
      await ApiClient.cancelBooking(this.bookingId)
      // ApiClient.adminBookingsCache()

      this.recurrentDate = {
        date: null,
        time: null,
      }
      this.recurrentTime = null
      this.recurrentDate = null
      this.bookingForm = {
        id: null, // for update booking only (unneccessary for new booking)
        date: null,
        package: null,
        location: {
          description: '',
          main_text: '',
          place_id: '',
          secondary_text: '',
        },
        accommodation: null,
        duration: null,
        services: null,
        additional: [],
        method: 0, // 0 = Auto, 1 = Manual
        maid: null,
        recurrentDates: [],
        recurrentList: [],
        recurrentItems: {},
        description: '',
        isChangeMaidBefore: false,
        isChangeMaid: false,
        currentMaid: null,
        historyRecurrentItems: [],
        bookingCalendars: [],
        bookingChangedMaid: [],
        notBookedTimes: 0,
        bookedTimes: 0,
        canChangeMaid: true,
        isAlreadyChangeMaid: false, // Booking นี้ถูกเปลี่ยนเมดไปแล้วหรือยัง ?
        payment: null,
        preventFetchBooking: false,
      }
      this.deleteRecurrentDateIndex = null
      this.modalConfirmType = ''
      this.totalPrice = 0
      this.calendar = []
      this.isAgreePolicy = false
      this.notAvailableTimeSlots = [] // วันที่ไม่ว่างใน recurrent items

      this.selectedDate = '' // ใช้้สำหรับแสดงรายละเอียดใต้ปฏิทิน
      this.previousMultiTimesOptions = null // ใช้สำหรับ Restore ค่า option เดิม
      this.previousDurationOptions = null

      // Calendar Attributes
      this.maidDisabledDates = [] // วันที่เมดไม่รับงาน
      this.disabledDatesAttrs = [] // วันที่ไม่สามารถกดได้บนปฏิทิน
      this.maidAvailableDateTimes = [] // วันเวลาที่เมดสามารถรับงานได้

      this.errors = [] // key of errors
      this.canChangeMaid = true

      this.descriptionTimeout = null
      this.isRestoring = false

      this.checkPendingNewMaid()

      this.$router.push({
        name: 'History',
        query: {
          tab: 'refund',
        },
      })
    } catch (e) {
      console.log(e.response)
    } finally {
      this.$root.$emit('bv::hide::modal', 'base-modal')
    }
  }

  @Watch('route')
  onRouteChanged(newVal) {
    if (newVal === 'BookingMultiPackage') {
      this.recurrentDate = {
        date: null,
        time: null,
      }
      this.recurrentTime = null
      this.recurrentDate = null
      this.bookingForm = {
        id: null, // for update booking only (unneccessary for new booking)
        date: null,
        package: null,
        location: {
          description: '',
          main_text: '',
          place_id: '',
          secondary_text: '',
        },
        accommodation: null,
        duration: null,
        services: null,
        additional: [],
        method: 0, // 0 = Auto, 1 = Manual
        maid: null,
        recurrentDates: [],
        recurrentList: [],
        recurrentItems: {},
        description: '',
        isChangeMaidBefore: false,
        isChangeMaid: false,
        currentMaid: null,
        historyRecurrentItems: [],
        bookingCalendars: [],
        bookingChangedMaid: [],
        notBookedTimes: 0,
        bookedTimes: 0,
        canChangeMaid: true,
        isAlreadyChangeMaid: false, // Booking นี้ถูกเปลี่ยนเมดไปแล้วหรือยัง ?
        payment: null,
        preventFetchBooking: false,
      }
      this.deleteRecurrentDateIndex = null
      this.modalConfirmType = ''
      this.totalPrice = 0
      this.calendar = []
      this.isAgreePolicy = false
      this.notAvailableTimeSlots = [] // วันที่ไม่ว่างใน recurrent items

      this.selectedDate = '' // ใช้้สำหรับแสดงรายละเอียดใต้ปฏิทิน
      this.previousMultiTimesOptions = null // ใช้สำหรับ Restore ค่า option เดิม
      this.previousDurationOptions = null

      // Calendar Attributes
      this.maidDisabledDates = [] // วันที่เมดไม่รับงาน
      this.disabledDatesAttrs = [] // วันที่ไม่สามารถกดได้บนปฏิทิน
      this.maidAvailableDateTimes = [] // วันเวลาที่เมดสามารถรับงานได้

      this.errors = [] // key of errors
      this.canChangeMaid = true

      this.descriptionTimeout = null
      this.isRestoring = false

      this.$store.commit('booking/setCurrentBookingMultiPackage', {
        ...this.currentBookingMultiPackage,
        recurrentList: [],
      })

      this.checkPendingNewMaid()
    } else {
      // BookingMultiPackage -> BookingMultiPackage Detail
      this.fetchBookingDetail()
    }
  }

  moveTo(element) {
    this.$scrollTo(element, 1100, {
      easing: 'ease-in-out',
      offset: -100,
    })
  }

  async onClose() {
    await this.$store.commit('maid/resetMaidsList')
    const path = this.$route.query.from
    const query = this.$route.query
    if (query.callback) {
      this.$router.push({ name: query.callback, query: { tab: query.tab } })
      return
    }
    if (path) {
      this.$router.push({ path: `/${path}` })
    } else {
      //  this.$router.go(-1)
      this.$router.push({ name: 'MyBooking' })
    }
  }

  contactWithAdmin() {
    window.open('https://m.me/bluuumaids/')
    return
    // if (!this.isLoggedIn) {
    //   window.open('https://m.me/bluuumaids/')
    //   return
    // }
    // const channel = this.sbChannelList.find((o) => o.memberMap.hasOwnProperty(ADMIN_ID))
    // if (channel) {
    //   this.$router.push({
    //     path: `/chats/message/${this.sbUser.userId}/${channel.url}`,
    //   })
    // } else {
    //   console.error('Channel is empty')
    // }
  }

  toggleModal(show = true, modalId) {
    if (show) {
      this.$root.$emit('bv::show::modal', modalId)
      console.log('show', modalId)
    } else {
      this.$root.$emit('bv::hide::modal', modalId)
    }
  }

  timeOut = null
  @Watch('multiPackage.recurrentList', { deep: true })
  onReccurrentListChanged() {
    clearTimeout(this.timeOut)
    this.timeOut = setTimeout(async () => {
      this.$refs.calendar && this.$refs.calendar.$forceUpdate()
      if (this.isBookingMultiPackage && this.$refs.calendar) {
        this.calendarKey += 1
      }
    }, 500)
  }

  // Return true หาก user มีการปรับเปลี่ยนข้อมูล
  get isUserChangeAnyField() {
    if (this.isBookingMultiPackage || this.multiPackage.isChangeMaid) return true

    return (
      this.isUpdateTimeslotAndMaid ||
      this.isUpdateLocation ||
      this.isUpdateAdditional ||
      this.isUpdateDescription ||
      this.isUpdateServices ||
      this.isUpdateAccommodation
    )
  }

  get isUpdateTimeslotAndMaid() {
    // Check timeslot is changed ?
    const previousTimeslots = sortTimeSlots([
      ...this.calendarChangedMaid,
      ...this.multiPackage.bookingCalendars,
    ])

    // Current timeslot
    const currentTimeslots = this.multiPackage.recurrentList.filter((rc) => !!rc.start_datetime)
    const sortedCurrentTimeslots = sortTimeSlots(currentTimeslots)
    // if (sortedCurrentTimeslots.length === 0) return false

    // Case Delete OR Add timeslot
    if (
      previousTimeslots.length !== sortedCurrentTimeslots.length &&
      !this.multiPackage.isChangeMaid
    ) {
      return true
    }

    // หลังจากที่มีการ Sort วันเวลา หากมีวันที่ไม่เหมือนกัน แปลว่ามีการเปลี่ยน
    let timeSlotCount = previousTimeslots.length
    for (let index = 0; index < timeSlotCount; index++) {
      const t1 = sortedCurrentTimeslots[index]
      const t2 = previousTimeslots[index]
      if (t1 && t2) {
        if (t1.start_datetime !== t2.start_datetime || t1.end_datetime !== t2.end_datetime) {
          return true
        }
      }
    }

    // Case: เปลี่ยนแม่บ้าน
    const { isChangeMaid, historyRecurrentItems } = this.multiPackage
    if (isChangeMaid && historyRecurrentItems.length !== sortedCurrentTimeslots.length) {
      return true
    }

    return false
  }

  get isUpdateDescription() {
    return this.currentBookingMultiPackage.description !== this.bookingForm.description
  }

  get isUpdateAdditional() {
    const additionalBefore = [...this.currentBookingMultiPackage.additional].sort().toString()
    const additionalAfter = [...this.bookingForm.additional].sort().toString()
    return additionalBefore !== additionalAfter
  }

  get isUpdateServices() {
    return (
      this.bookingForm.services &&
      this.currentBookingMultiPackage.services &&
      this.bookingForm.services.value !== this.currentBookingMultiPackage.services.value
    )
  }

  get isUpdateAccommodation() {
    return (
      this.bookingForm.accommodation &&
      this.currentBookingMultiPackage.accommodation &&
      this.bookingForm.accommodation.value !== this.currentBookingMultiPackage.accommodation.value
    )
  }

  get isUpdateLocation() {
    return (
      this.currentBookingMultiPackage.location.main_text !== this.bookingForm.location.main_text ||
      (this.bookingForm.location.additional_details &&
        this.bookingForm.location.additional_details !==
          this.multiPackage.location_additional_details)
    )
  }

  get disableReccurentDate() {
    if (this.bookingForm.package === null || this.isFetchingBookingDetail) return true
    // const hasDefaultTimeslot = this.multiPackage.historyRecurrentItems.length > 0
    if (this.multiPackage.isChangeMaid) return false
    if (this.isMyBookingMultiPackage && this.multiPackage.notBookedTimes != 0) return false
    return !this.isBookingMultiPackage
  }

  get disabledAddressSelector() {
    if (this.isBookingMultiPackage) return false // New booking
    if (this.hasUsedTimeSlot) return true
    if (this.hasApprovedTimeSlot) return true
    if (this.hasUpcomingBooking) return true
    if (this.isMaidRejectEntirePackage) return true
    if (this.disableChange) return true
    return false
  }

  get disableChange() {
    console.log('this.multiPackage', this.multiPackage)
    return this.multiPackage.isAlreadyChangeMaid || this.isReview
  }

  get showChatBtn() {
    if (this.isBookingMultiPackage || this.multiPackage.isChangeMaid) {
      return false
    }
    if (
      !this.bookingForm.maid ||
      this.bookingForm.maid.user.phone == null ||
      this.bookingForm.maid.user.phone == ''
    )
      return false
    return true
  }

  get isMaidPublished() {
    return (this.bookingForm.maid && this.publish_status === 'PUBLISHED') || !this.bookingForm.maid
  }

  // async toChatPage() {
  //   const channel = await this.$store.dispatch(
  //     'sbChannel/getChannelByUserID',
  //     this.multiPackage.currentMaid.user.id
  //   )
  //   if (channel) {
  //     const user = this.$store.state.sbUser.sbUser
  //     this.$router.push({
  //       path: `/chats/message/${user.userId}/${channel.url}`,
  //       query: { from: 'bookingDetail' },
  //     })
  //   }
  // }

  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  }
  toCallMaid() {
    if (this.isMobile()) {
      // window.open(`tel:${this.bookingForm.maid.user.phone}`, '_system');
      const link = document.createElement('a')
      link.href = `tel:${this.bookingForm.maid.user.phone}`
      link.click()
    } else {
      this.$root.$emit('bv::show::modal', 'maid-phone-number')
    }
  }

  get grandTotal() {
    if (this.isMultiPackageCompleted || this.isCanceledByCustomer)
      return this.currentBookingMultiPackage.payment_total_price

    if (!this.isBookingMultiPackage) {
      // return this.totalPrice
      // Note: Following the business requirement
      // Booking MP CAN'T upgrade or change any fields that maybe effect to total price
      // It's mean we can always set the grandTotal price for update booking MP to 0 THB
      return 0
    }
    // alert("this.totalPrice",this.totalPrice)
    // New booking
    return this.totalPrice
  }

  get showCondoWarningCheck() {
    if (!this.isBookingMultiPackage) {
      if (!this.isUpdateAccommodation && !this.isUpdateTimeslotAndMaid) return false
    }

    const { accommodation, recurrentDates, recurrentList } = this.bookingForm
    const recurrentListMultiPackage = this.multiPackage.recurrentList

    if (!accommodation || (!recurrentDates && !recurrentList && !recurrentListMultiPackage))
      return false

    if (!['CONDO_1_BR', 'CONDO_2_BR', 'CONDO_3_BR', 'CONDO_4_BR'].includes(accommodation.value))
      return false

    if (recurrentDates && recurrentDates.length > 0) {
      for (let i = 0; i < recurrentDates.length; i++) {
        const date = recurrentDates[i]
        if (
          date.time &&
          date.time.startHour &&
          (date.time.startHour > 15 || date.time.startHour < 8)
        )
          return true
      }
    }

    if (recurrentList && recurrentList.length > 0) {
      for (let i = 0; i < recurrentList.length; i++) {
        const date = recurrentList[i]
        if (
          date.time &&
          date.time.startHour &&
          (date.time.startHour > 15 || date.time.startHour < 8)
        )
          return true
      }
    }

    if (recurrentListMultiPackage && recurrentListMultiPackage.length > 0) {
      for (let i = 0; i < recurrentListMultiPackage.length; i++) {
        const date = recurrentListMultiPackage[i]
        if (
          date.time &&
          date.time.startHour &&
          (date.time.startHour > 15 || date.time.startHour < 8)
        )
          return true
      }
    }

    return false
  }

  @Watch('showCondoWarningCheck')
  onShowCondoWarningCheckChange() {
    if (
      this.showCondoWarningCheck &&
      !this.condoWarningDisplayed &&
      !this.accommodation_confirmed
    ) {
      // let prevTimeCondoWarning = localStorage.getItem('timeCondoWarning')
      // if (prevTimeCondoWarning) {
      //   let timeCondoWarning = new Date(prevTimeCondoWarning)
      //   let currentTime = new Date()
      //   let diff = currentTime - timeCondoWarning
      //   if (diff < 1000 * 60 * 5) {
      //     return
      //   }
      // }

      this.$root.$emit('bv::show::modal', 'confirm-condo-modal')

      // let newTimeCondoWarning = new Date()
      // localStorage.setItem('timeCondoWarning', newTimeCondoWarning)
    }
  }

  dontShowCondoWarning = false
  condoWarningDisplayed = true
  async confirmCondoWarning() {
    if (this.dontShowCondoWarning) {
      try {
        await ApiClient.updateUserAccommodationConfirmation()
        await this.updateUserAccommodation()
      } catch (error) {
        console.log(error)
      }
    }

    this.condoWarningDisplayed = true
    this.$root.$emit('bv::hide::modal', 'confirm-condo-modal')
  }

  dateTimeTimezone = ''
  getDateTimeTimezone() {
    const d = new Date()

    // Get date components
    const day = d.getDate()
    const month = d.getMonth() + 1 // Month is zero-based, so add 1
    const year = d.getFullYear()

    // Get time components
    const hours = d.getHours()
    const minutes = d.getMinutes()

    // Get timezone offset in minutes
    const timezoneOffset = d.getTimezoneOffset()

    // Convert timezone offset to hours and minutes
    const absTimezoneOffsetHours = Math.abs(timezoneOffset / 60)
    const timezoneOffsetHours = Math.floor(absTimezoneOffsetHours)
    const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60)

    // Determine the sign of the timezone offset
    const timezoneSign = timezoneOffset >= 0 ? '-' : '+'

    // Format the date/time and timezone
    const formattedDateTime = `${day}/${month}/${year} ${hours}:${
      minutes < 10 ? '0' : ''
    }${minutes}`
    const formattedTimezone = `(GMT${timezoneSign}${
      timezoneOffsetHours < 10 ? '0' : ''
    }${timezoneOffsetHours}:${timezoneOffsetMinutes < 10 ? '0' : ''}${timezoneOffsetMinutes})`

    return `${formattedDateTime} ${formattedTimezone}`
  }
}
</script>

<style lang="scss" scoped>
.text-timestamp {
  font-size: 12px;
  margin-top: 10px;
  opacity: 0.7;
}
.btn-close {
  background: transparent;
  border: none;
  outline: none;
  vertical-align: middle;
  color: #757575;
  &:hover {
    color: black;
  }
}
// .tooltip .tooltip-inner
//   max-width: 500px !important;
//   width: 400px !important;
//   .veryLongTooltip .tooltip-inner
//     max-width: 100%;
//
//
</style>
<style lang="scss">
.marb8 {
  margin-bottom: 8px !important;
}
@media screen and (min-width: 320px) and (max-width: 375px) {
  img#multipackage-icon {
    max-width: 16px;
  }
  .tooltip-inner {
    max-width: 230px;
    margin-right: 30px;
  }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
  .tooltip-inner {
    max-width: 280px;
    margin-left: 20px;
  }
}
@media screen and (min-width: 425px) and (max-width: 767px) {
  .tooltip-inner {
    max-width: 330px;
    margin-left: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 2560px) {
  .tooltip-inner {
    /*max-width: none !important;*/
    max-width: 400px;
    margin-left: 2rem;
  }
}
.equipment-tooltip {
  max-width: 400px;
  justify-content: center;
}
.weekday-tooltip {
  max-width: 330px;
  justify-content: center;
}
.avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
</style>
