<template>
  <div class="time-slot-item-container" :class="{ expanded: isExpanding }">
    <div class="time-slot-item" v-if="!isUpdating">
      <div class="d-flex align-items-center time-slot-detail">
        <div class="index-number">{{ item.number }}</div>
        <div
          v-if="item.start_datetime"
          :class="{
            'disable-item': multiPackage.isAlreadyChangeMaid || isServiceCompleted,
          }"
        >
          <p class="m-0">{{ `${dateLabel} | ${timeLabel}` }}</p>
          <span class="text-capitalize">{{ maidName }}</span>
          <span v-if="$i18n.locale !== 'th'">{{ ` is ` }}</span>
          <span class="available" v-if="isAvailable || isServiceCompleted">
            {{ $t('status.available') }}
            <i class="fas fa-check-circle"></i>
          </span>
          <span class="not-available" v-else>
            {{ $t('status.not_available') }}
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          <b-badge
            pill
            :variant="badgeVariant"
            class="ml-2 text-white font-weight-normal"
            v-if="shouldShowBadge"
            >{{ badgeLabel }}</b-badge
          >
        </div>
        <div v-else class="d-flex align-items-center">
          <span>{{ $t('form_label.please_choose_date_time') }}</span>
        </div>
      </div>
      <b-button
        v-if="!disableChangeTimeSlot"
        :variant="`${isAvailable && !isCanceledByMaid ? 'outline-warning' : 'warning'}`"
        size="md"
        class="change-btn btn-change-custom"
        @click="isExpanding = !isExpanding"
        >{{ isExpanding ? $t('button.close') : $t('button.change') }}</b-button
      >
      <b-button
        variant="outline-dark-light"
        style="padding: 0 24px; cursor: not-allowed"
        disabled
        v-else
        class="btn-change-custom"
        >{{ $t('button.change') }}</b-button
      >
    </div>
    <div v-else class="time-slot-skeleton d-flex" style="height: 70px">
      <Skeleton width="60px" height="60px" circle :loading="true"></Skeleton>
      <div style="padding: 5px; width: 100%">
        <Skeleton width="100%" height="20px" :loading="true"></Skeleton>
        <Skeleton width="100%" height="20px" :loading="true"></Skeleton>
      </div>
    </div>
    <div v-if="isExpanding" class="expand-area-container">
      <div>{{ $t('button.pick_date') }}</div>
      <b-form-row class="my-2 custom-input-datetime">
        <b-col>
          <b-input-group>
            <b-form-input
              class="input-date"
              :placeholder="$t('form_label.pick_a_date_from_calendar')"
              disabled
              v-model="inputDateTimeValue"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="link" class="input-fa-calendar">
                <img :src="require('../assets/images/icons/datepicker.png')" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-form-row>

      <div class="d-flex justify-content-center">
        <v-calendar
          ref="calendar"
          :locale="$i18n.locale"
          class="v-calendar-custom"
          :min-date="minDate"
          :attributes="calendarAttrs"
          is-expanded
          @dayclick="onSelectedDate"
          @update:from-page="onMonthChanged"
        ></v-calendar>
      </div>
      <div>{{ $t('button.pick_time') }}</div>
      <b-form-row>
        <b-col>
          <b-form-group>
            <v-select
              :labelTitle="$t('form_label.no_available_time')"
              :disabled="timeOptionsNothingSelect"
              class="custom-v-select"
              v-if="timeOptionsNothingSelect"
            />
            <v-select
              :labelTitle="$t('form_label.nothing_selected')"
              :options="timeOptions"
              v-model="form.time"
              :disabled="!form.start_datetime || isFetchingCalendar"
              class="custom-v-select"
              v-else
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row class="mb-4">
        <b-col>
          <b-button
            variant="danger"
            size="md"
            block
            @click="deleteDate"
            :disabled="upcomingInOneDay"
            >{{ $t('button.delete') }}</b-button
          >
        </b-col>
        <b-col>
          <b-button
            variant="warning"
            size="md"
            block
            @click="confirmChange"
            :disabled="!canConfirm"
            >{{ $t('button.confirm') }}</b-button
          >
        </b-col>
      </b-form-row>
      <p class="text-danger text-center mt-0" v-if="errorMsg">{{ errorMsg }}</p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import VSelect from '@alfsnd/vue-bootstrap-select'
import Constant, { STATUS } from '../config/booking'
import moment from 'moment'
import { Skeleton } from 'vue-loading-skeleton'
import {
  // getDisabledDateFromTimeSlot,
  createEmptyTimeSlot,
  minimumBookingMoment,
  isToday,
} from '../utils'
import {
  isTimeSlotInServiceTime,
  mergeOverlapTimeSlot,
  isDateInTimeSlots,
  getDisabledDateFromTimeSlot,
} from '../helpers/calendar'
import get from 'lodash/get'

import VueContentLoading from 'vue-content-loading'
import { auth } from 'firebase'

const BookingStore = createNamespacedHelpers('booking')
const Calendar = createNamespacedHelpers('calendar') // maid calendar
const MaidStore = createNamespacedHelpers('maid')
const AuthStore = createNamespacedHelpers('auth')
@Component({
  methods: {
    ...BookingStore.mapMutations(['updateRecurrentItem']),
    ...Calendar.mapActions([
      'updateMaidCalendarByMonth',
      'isAvailableTimeSlot',
      'fetchMaidCalendarAllMonth',
      'fetchMaidCalendarAllMonthPublic',
    ]),
    ...Calendar.mapMutations(['setCurrentUpdatingTimeSlotNumber', 'incrementRenderCount']),
  },
  props: {
    item: {
      number: Number,
      date: String,
      time: Object,
      dateString: String,
    },
    payment_status: String,
  },
  computed: {
    ...BookingStore.mapState(['multiPackage']),
    ...Calendar.mapState([
      'maidCalendar',
      'isFetchingCalendar',
      'isInitializeTimeSlot',
      'currentUpdatingTimeSlotNumber',
      'currentBookingId',
    ]),
    ...MaidStore.mapState(['maidsObject']),
    ...Calendar.mapGetters(['calendarTotalHours', 'baseDisabledHighlight']),
    ...AuthStore.mapState(['isLoggedIn']),
  },
  components: {
    VSelect,
    VueContentLoading,
    Skeleton,
  },
})
export default class TimeSlot extends Vue {
  isShowTooltip = false
  errorMsg = ''
  form = {
    start_datetime: null,
    end_datetime: null,
    time: null,
  }
  isExpanding = false
  maidDisabledDates = [] // วันที่เมดไม่รับงาน

  selectedDate = null
  _isUpdate = false

  onSelectedDate(date) {
    // Check is in disable ()
    // console.log("date timeslot", date);
    const isDisabledDate = this.disabledGreyHighlight
      .concat(this.baseDisabledHighlight)
      .some(({ dates }) => {
        const today = isToday(moment(date.dateTime).toISOString())
        return moment(date.id).isSame(moment(dates), 'date') || today
      })

    if (isDisabledDate) return

    const start_datetime = moment
      .tz(date.id + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss', 'UTC')
      .toISOString()

    if (isToday(start_datetime) || moment(start_datetime) < moment()) return
    // กำหนดค่าเริ่มต้น
    this.selectedDate = start_datetime
    this.form = { start_datetime, end_datetime: start_datetime, time: null }
  }

  /**
   * @param {Object} - page => { month: int, year: int }
   */
  async onMonthChanged(page) {
    if (this.multiPackage.maid) {
      await this.getMaidBookingTotal({
        id: this.multiPackage.maid.id,
        ...page,
      })
    }
  }

  async getMaidBookingTotal(payload) {
    try {
      console.log('payload', payload)
      const maidCalendar = this.isLoggedIn
        ? await this.fetchMaidCalendarAllMonth(payload)
        : await this.fetchMaidCalendarAllMonthPublic(payload)
      // วันที่เมดไม่สามารถรับงานได้
      this.maidDisabledDates = getDisabledDateFromTimeSlot(maidCalendar)
    } catch (e) {
      console.log(e.response)
    }
  }

  deleteDate() {
    this.isExpanding = false
    const timeSlot = createEmptyTimeSlot(this.item.number)
    this.incrementRenderCount()
    this.updateRecurrentItem(timeSlot)
  }

  get isNewChange() {
    return !this.isExistInBookingCalendar && !this.isExistInChangedMaidCalendars
  }

  get isExistInChangedMaidCalendars() {
    if (!this.bookingChangedMaid) return false
    const isExistInChangedMaidCalendars = this.bookingChangedMaid.find(({ booking_calendars }) => {
      const isExist = booking_calendars.find((timeSlot) => {
        return (
          timeSlot.start_datetime === this.item.start_datetime &&
          timeSlot.end_datetime === this.item.end_datetime
        )
      })
      return isExist
    })
    return Boolean(isExistInChangedMaidCalendars)
  }

  get isExistInBookingCalendar() {
    const isExistInBookingCalendar = this.bookingCalendars.find((timeSlot) => {
      return (
        (timeSlot.start_datetime === this.item.start_datetime &&
          timeSlot.end_datetime === this.item.end_datetime &&
          !this.multiPackage.isChangeMaid) ||
        this.disableChangeTimeSlot
      )
    })
    return Boolean(isExistInBookingCalendar)
  }

  get isBookingMultiPackage() {
    return this.$route.name === 'BookingMultiPackage'
  }

  async confirmChange() {
    // console.log(this.recurrentItem);
    if (!this.form.time) {
      this.errorMsg = 'Please select time.'
      return
    }
    this.setCurrentUpdatingTimeSlotNumber(this.item.number)
    const time = this.form.time
    const startDate = this.$momentTZ(this.form.start_datetime)
      .hour(time.startHour)
      .minute(time.startMinute)
    const endDate = this.$momentTZ(this.form.end_datetime).hour(time.endHour).minute(time.endMinute)

    const start_datetime = startDate.toISOString()
    const end_datetime = endDate.toISOString()

    // หากเป็นวันเวลาเดิม จะไม่อัพเดทเข้า state
    if (start_datetime === this.recurrentItem.start_datetime) {
      this.setCurrentUpdatingTimeSlotNumber(0)
      this.isExpanding = !this.isExpanding
      return
    }

    const isAvailableBefore = this.recurrentItem.isAvailable
    // const isAvailable = await this.isAvailableTimeSlot({ start_datetime, end_datetime })
    const isAvailable = true // Should always be true (has only available time in options)

    // เช็คว่าวันเวลาที่เลือกมาใหม่มีอยู่แล้วใน Booking Calendar ไหม
    // ถ้ามีอยู่จะดึง Status เดิมมาใส่
    const calendarTimeSlot = this.bookingCalendars.find((timeSlot) => {
      return timeSlot.start_datetime === start_datetime && timeSlot.end_datetime === end_datetime
    })

    const newStatus = this.getNewStatus()
    // console.log("newStatus", newStatus);
    this.validateTimeSlot({ start_datetime, end_datetime })
    let status

    if (!!calendarTimeSlot) {
      status = calendarTimeSlot.status
    } else {
      status = this.getNewStatus()
    }

    // if (this.isBookingMultiPackage || this.multiPackage.isChangeMaid) {
    //   status = null
    // } else if (!isAvailableBefore && isAvailableBefore !== null && this.isBookingMultiPackage) {
    //   status = STATUS.updateAwaiting
    // } else if (!calendarTimeSlot) {
    //   // Update booking (Already booked)
    //   status = STATUS.updateApproved
    // } else if (!!calendarTimeSlot) {
    //   status = calendarTimeSlot.status
    // } else {
    //   status = null
    // }

    const newRecurrentItem = {
      start_datetime,
      end_datetime,
      number: this.item.number,
      time: this.form.time,
      isAvailable,
      status,
    }
    this.incrementRenderCount()
    this.updateRecurrentItem(newRecurrentItem)
    this.setCurrentUpdatingTimeSlotNumber(0)
    this.isExpanding = !this.isExpanding
  }

  validateTimeSlot({ start_datetime, end_datetime }) {
    console.log(start_datetime, end_datetime)
  }

  getNewStatus() {
    const { status } = this.recurrentItem
    // Add more time slot (Booking Detail Page)
    if (!this.isBookingMultiPackage && !this.multiPackage.isChangeMaid && !status) {
      return STATUS.updateAwaiting
      // return this.hasApprovedTimeSlot ? STATUS.updateAwaiting : STATUS.awaiting
    }
    // Update current booked time slot
    switch (this.recurrentItem.status) {
      case STATUS.approved:
        return STATUS.updateApproved
      case STATUS.awaiting:
        return STATUS.updateAwaiting
      case STATUS.updateApproved:
        return STATUS.updateApproved
      case STATUS.updateAwaiting:
        return STATUS.updateAwaiting
      case STATUS.canceledByMaid:
        return STATUS.updateAwaiting
      default:
        return null
    }
  }

  async mounted() {
    this.form = this.item
    console.log('xxxxxxxxxxxxxxxx', this.isLoggedIn)
  }
  updated() {
    if (!this._isUpdate && this.$refs.calendar) {
      const calendar = this.$refs.calendar
      // console.log("calendar", calendar);

      calendar.move(new Date(this.item.start_datetime))

      this._isUpdate = true
    }
  }

  @Watch('isExpanding')
  onClickChangeButton(newVal, oldVal) {
    this.form = { ...this.item }
    this.selectedDate = null
    console.log('newVal', newVal)
    if (newVal) {
      this.$nextTick(() => {
        const calendar = this.$refs.calendar
        if (this.recurrentItem && this.recurrentItem.start_datetime) {
          calendar.move(new Date(this.recurrentItem.start_datetime))
        } else {
          if (this.item.number > 1) {
            const prevTimeSlot = this.multiPackage.recurrentList.find(
              (timeSlot) => timeSlot.number === this.item.number - 1
            )
            if (prevTimeSlot && prevTimeSlot.start_datetime) {
              const prevTimeSlotNextDay = moment(prevTimeSlot.start_datetime)
                .add(1, 'days')
                .toDate()
              calendar.move(prevTimeSlotNextDay)
              this.onSelectedDate({
                id: moment(prevTimeSlotNextDay).format('YYYY-MM-DD'),
                dateTime: new Date(prevTimeSlotNextDay).getTime(),
              })
            }
          }
        }
      })
    }
  }

  get isCanceledByCustomer() {
    return this.multiPackage.recurrentList.some(
      (booking) => booking.status === STATUS.canceledByCustomer
    )
  }

  get isCanceledByMaid() {
    return this.recurrentItem.status === STATUS.canceledByMaid
  }

  get hasApprovedTimeSlot() {
    return this.multiPackage.recurrentList.some((booking) => booking.status === STATUS.approved)
  }

  get isMaidRejectEntirePackage() {
    return this.multiPackage.recurrentList.every(
      (booking) => booking.status === STATUS.canceledByMaid
    )
  }

  get bookingCalendars() {
    return this.multiPackage.bookingCalendars
  }

  get bookingChangedMaid() {
    return this.multiPackage.bookingChangedMaid
  }

  get shouldShowBadge() {
    if (this.isServiceCompleted) return true

    const statusList = [
      STATUS.updateAwaiting,
      STATUS.approved,
      STATUS.updateApproved,
      STATUS.updateApproved,
      STATUS.canceledByMaid,
    ]
    return statusList.indexOf(this.recurrentItem.status) > -1 && !this.isNewChange
  }

  get badgeTooltipText() {
    switch (this.recurrentItem.status) {
      case STATUS.awaiting:
        return this.$t('status.waiting_for_maids_confirmation')
      case STATUS.updateAwaiting:
        return this.$t('status.waiting_for_maids_confirmation')
      case STATUS.updateApproved:
        return this.$t('status.waiting_for_maids_confirmation')
      default:
        return ''
    }
  }

  get badgeVariant() {
    if (this.isServiceCompleted) {
      return `dark`
    }

    switch (this.recurrentItem.status) {
      case STATUS.approved:
        return `warning`
      case STATUS.updateAwaiting:
        return `primary`
      case STATUS.updateApproved:
        return `primary`
      default:
        return `danger`
    }
  }

  get badgeLabel() {
    if (this.isServiceCompleted) {
      return this.$t('status.service_completed')
    }

    switch (this.recurrentItem.status) {
      case STATUS.approved:
        return this.$t('status.booking_confirmed')
      case STATUS.updateAwaiting:
        return this.$t('status.update_sent')
      case STATUS.updateApproved:
        return this.$t('status.update_sent')
      case STATUS.canceledByMaid:
        return !this.isMaidRejectEntirePackage ? this.$t('status.booking_unsuccessful') : ``
      default:
        return ''
    }
  }

  get recurrentItem() {
    return this.multiPackage.recurrentList.find((timeSlot) => timeSlot.number === this.item.number)
  }

  // e.g. `Nov 11 Mon`
  get dateLabel() {
    let template = this.$i18n.locale === 'th' ? `dd DD MMM 'YY` : `ddd MMM DD 'YY`
    if (this.recurrentItem) {
      return moment(this.item.start_datetime).hour(0).format(template)
    } else {
      return '-'
    }
  }

  get timeLabel() {
    const startDate = this.$momentTZ(this.recurrentItem.start_datetime)
    const endDate = this.$momentTZ(this.recurrentItem.end_datetime)
    return `${startDate.format('H:mm')}-${endDate.format('H:mm')}`
  }

  get maidName() {
    if (this.recurrentItem.maid_id) {
      const maid_id = this.recurrentItem.maid_id
      return this.maidsObject[maid_id] ? this.maidsObject[maid_id].user.first_name : ``
    }
    return this.multiPackage.maid.user.first_name
  }

  get inputDateTimeValue() {
    if (!this.form.start_datetime) return ''
    const template = this.$i18n.locale === 'th' ? 'ddd DD MMM YYYY' : 'ddd MMM DD, YYYY'
    return moment(this.form.start_datetime).format(template)
  }

  get canConfirm() {
    return !!this.form.time && !this.upcomingInOneDay
  }

  get calendarAttrs() {
    return [
      this.currentSelectDateHighlight,
      ...this.baseDisabledHighlight,
      ...this.popovers,
      ...this.grayDots,
      ...this.whiteDots,
      ...this.disabledGreyHighlight,
      {
        key: 'busy',
        highlight: this.isAvailable ? 'green' : 'red',
        content: 'white',
        dates: this.recurrentItem
          ? this.$momentTZ(this.recurrentItem.start_datetime).locale(this.$i18n.locale).format()
          : null,
      },
    ]
  }

  get minDate() {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    return date
  }

  get currentSelectDateHighlight() {
    return {
      key: 'selectedDay',
      bar: true,
      dates: this.selectedDate
        ? this.selectedDate
        : this.$momentTZ(this.form.start_datetime).locale(this.$i18n.locale).format(),
    }
  }

  get grayDots() {
    // console.log("this.calendarTotalHours", this.calendarTotalHours);
    const calendar = this.calendarTotalHours.filter((d) => {
      return !moment(d.date).isSame(moment(this.recurrentItem.start_datetime), 'date')
    })

    const firstDot = {
      key: 'firstDot',
      dot: { class: 'custom-dot-gray' },
      dates: [],
    }
    const secondDot = {
      key: 'secondDot',
      dot: { class: 'custom-dot-gray' },
      dates: [],
    }
    const thirdDot = {
      key: 'thirdDot',
      dot: { class: 'custom-dot-gray' },
      dates: [],
    }
    calendar.forEach((d) => {
      const newDate = d.date
      const isDisableDate = isDateInTimeSlots(d.date, [
        ...this.maidDisabledDates,
        ...this.baseDisabledHighlight,
      ])
      if (parseInt(d.total_hours) >= 2 && !isDisableDate) firstDot.dates.push(newDate)
      if (parseInt(d.total_hours) >= 4 && !isDisableDate) secondDot.dates.push(newDate)
      if (parseInt(d.total_hours) >= 6 && !isDisableDate) thirdDot.dates.push(newDate)
    })
    return [firstDot, secondDot, thirdDot]
  }

  get whiteDots() {
    const calendar = this.calendarTotalHours.filter((d) => {
      return moment(d.date).isSame(moment(this.recurrentItem.start_datetime), 'date')
    })

    const firstDot = {
      key: 'firstWhiteDot',
      dot: { class: 'custom-dot-white' },
      dates: [],
    }
    const secondDot = {
      key: 'secondWhiteDot',
      dot: { class: 'custom-dot-white' },
      dates: [],
    }
    const thirdDot = {
      key: 'thirdWhiteDot',
      dot: { class: 'custom-dot-white' },
      dates: [],
    }
    calendar.forEach((d) => {
      const newDate = moment(d.date).toDate()
      const isDisableDate = isDateInTimeSlots(d.date, [
        ...this.maidDisabledDates,
        ...this.baseDisabledHighlight,
      ])
      if (parseInt(d.total_hours) >= 2 && !isDisableDate) firstDot.dates.push(newDate)
      if (parseInt(d.total_hours) >= 4 && !isDisableDate) secondDot.dates.push(newDate)
      if (parseInt(d.total_hours) >= 6 && !isDisableDate) thirdDot.dates.push(newDate)
    })
    return [firstDot, secondDot, thirdDot]
  }

  /**
   * คืนค่าอาเรย์ของวันที่ต้อง highlight (เทา)
   */
  get popovers() {
    return this.maidDisabledDates
      .filter((d) => {
        if (!this.recurrentItem) return true
        const maidDate = moment(d.start_datetime)
        const currentDate = moment(this.recurrentItem.start_datetime)
        return !maidDate.isSame(currentDate, 'date')
      })
      .map((d) => ({
        key: 'popover',
        popover: {
          label: this.$t('form_label.not_available'),
          placement : 'auto',
        },
        dates: this.$momentTZ(d.start_datetime).locale(this.$i18n.locale).format(),
      }))
  }

  get disabledGreyHighlight() {
    return this.maidDisabledDates
      .filter((d) => {
        if (!this.recurrentItem) return true
        const maidDate = moment(d.start_datetime)
        const currentDate = moment(this.recurrentItem.start_datetime)
        return !maidDate.isSame(currentDate, 'date')
      })
      .map((d) => ({
        key: 'busy',
        highlight: 'gray',
        content: isToday(d.start_datetime) ? 'blue' : 'gray',
        dates: this.$momentTZ(d.start_datetime).locale(this.$i18n.locale).format(),
      }))
  }

  get maidAvailableTimeSlots() {
    if (!this.form.start_datetime) return []
    const selectDate = moment(this.form.start_datetime)
    const year = selectDate.year()
    const month = selectDate.month()

    const maidCalendar = get(this.maidCalendar, `${year}.${month + 1}`, [])
    const timeSlots = maidCalendar
      .filter((slot) => {
        const isOwnCustomerBooking =
          ['BOOKED', 'RESERVED'].includes(slot.type) && slot.booking.id === this.currentBookingId
        return (
          (slot.type === 'FREE' || isOwnCustomerBooking) &&
          moment(slot.start_datetime).isSame(selectDate, 'date') &&
          isTimeSlotInServiceTime(slot)
        )
      }) // เอาช่วงเวลาที่แม่บ้านว่าง + เป็นวันเดียวกันกับที่เลือกอยู่บนปฏิทิน
      .sort((a, b) => moment(a.start_datetime) - moment(b.start_datetime)) // เรียงวันจากน้อยไปมาก
    return mergeOverlapTimeSlot(timeSlots)
  }

  /**
   * วันเวลา options ตามที่ Maid ว่าง
   */
  get timeOptionsNothingSelect() {
    return this.timeOptions.length <= 0
  }
  get timeOptions() {
    // console.log("this.form.start_datetime", this.form.start_datetime);
    if (!this.form.start_datetime) return []
    const options = []
    let maidTimeSlots = this.maidAvailableTimeSlots // ช่วงเวลาที่แม่บ้านว่าง
    //  console.log("this.form.start_datetime", this.form.start_datetime);
    // สร้าง Base options โดยจะมีเฉพาะช่วงเวลาที่มากกว่า 24 Hrs
    const baseOptions = Constant.TIME_OPTIONS.filter((t) => {
      return (
        this.$momentTZ(this.form.start_datetime)
          .hour(t.hour) // set hour
          .minute(t.minute) > minimumBookingMoment()
      )
    })

    baseOptions.forEach((option) => {
      // สร้างช่วงเวลาเริ่ม - จบ โดยใช้วันที่เลือกอยู่ + ชั่วโมง/นาทีจาก Base options
      const startTime = this.$momentTZ(this.form.start_datetime)
        .hours(option.hour)
        .minute(option.minute)
        .second(0)
        .millisecond(0)
      const endTime = this.$momentTZ(this.form.start_datetime)
        .hours(option.hour + this.duration.value)
        .minute(option.minute)
        .second(0)
        .millisecond(0)

      const isWithinOneDay = startTime.diff(this.$momentTZ(), 'hour') < 3

      // console.log("isWithinOneDay", isWithinOneDay);
      if (isWithinOneDay) return

      // เช็คว่าวันนั้นแม่บ้านว่างไหม ถ้าว่างจะได้ time slot ของช่วงเวลาที่แม่บ้านว่างออกมา
      // หากแม่บ้านไม่ว่าง matchedDate จะเท่ากับ undefined
      const matchedDate = maidTimeSlots.find((d) => {
        const maidStartDate = moment(d.start_datetime)
        const maidEndDate = moment(d.end_datetime)
        return startTime >= maidStartDate && endTime <= maidEndDate
      })

      // ถ้าเจอเวลาที่แม่บ้านว่าง ให้เพิ่มลงใน array options
      if (matchedDate) {
        options.push({
          text: `${startTime.format('H:mm')} - ${endTime.format('H:mm')}`,
          startHour: startTime.hours(),
          startMinute: startTime.minutes(),
          endHour: endTime.hours(),
          endMinute: endTime.minutes(),
        })
      }
    })
    return options
  }

  // วันที่เลือกว่างหรือไม่
  get isAvailable() {
    return this.recurrentItem.isAvailable
  }

  get isApproved() {
    return this.recurrentItem.status === STATUS.approved
  }
  get isReview() {
    return this.payment_status === 'BANK_TRANSFER_REVIEWING'
  }

  // เวลาในการการทำงานของเมดแต่ละครั้ง
  get duration() {
    return this.multiPackage.duration //  { "text": "2 Hours", "value": 2 }
  }

  // ถูกใช้บริการไปแล้วหรือยัง
  get isPassed() {
    if (!this.item.end_datetime) return false
    let current_date = localStorage.getItem('currentDate') || new Date()
    let passed = new Date(current_date) > new Date(this.item.end_datetime)
    let adminToken = window.localStorage.getItem('admin_token')
    if (adminToken && adminToken !== 'null') {
      passed = new Date() > new Date(this.item.end_datetime)
    }
    return passed
  }

  get disableChangeTimeSlot() {
    // console.log("this.upcomingInOneDay", this.item);
    return (
      this.isServiceCompleted ||
      this.multiPackage.isAlreadyChangeMaid ||
      this.upcomingInOneDay ||
      this.isCanceledByCustomer ||
      this.isMaidRejectEntirePackage ||
      this.isReview
    )
  }

  get isServiceCompleted() {
    return this.isPassed && this.recurrentItem.status === STATUS.approved
  }

  get changableAtIndex() {
    return this.multiPackage.isChangeMaid
      ? this.multiPackage.historyRecurrentItems.length
      : this.multiPackage.bookingCalendars.length
  }

  get disabledDeleteButton() {
    return this.item.number - 1 < this.changableAtIndex
  }

  get isUpdating() {
    // return this.currentUpdatingTimeSlotNumber === this.item.number
    return this.currentUpdatingTimeSlotNumber === this.item.number || this.isInitializeTimeSlot
  }

  // Return true ถ้าเครดิตนี้จะเกิดขึ้นในอีก 24 ชั่วโมง
  get upcomingInOneDay() {
    const { start_datetime, end_datetime } = this.recurrentItem
    if (!start_datetime || !end_datetime || !this.isApproved) return false
    const startDateTime = moment(start_datetime)
    return startDateTime.diff(moment(), 'seconds') < 60 * 60 * 3
  }

  @Watch('isInitializeTimeSlot')
  onInitializeTimeSlot() {
    if (this.isInitializeTimeSlot) {
      // Set all time slot to not expand while calendar is initialize state
      this.isExpanding = false
    }
  }
  @Watch('timeOptions')
  onChangeTimeOptions(newVal) {
    // console.log("time change", newVal);
    if (
      this.form.time != null &&
      !newVal.map((timeSlot) => timeSlot.text).includes(this.form.time.text) &&
      (this.isAvailable ||
        this.isServiceCompleted ||
        this.recurrentItem.status == STATUS.approved ||
        this.recurrentItem.status == STATUS.updateApproved ||
        this.recurrentItem.status == STATUS.updateAwaiting)
    ) {
      this.timeOptions.unshift(this.form.time)
    }
    if (
      this.form.time == null ||
      newVal.map((timeSlot) => timeSlot.text).includes(this.form.time.text)
    )
      return
    this.form.time = null
  }
}
</script>

<style lang="scss" scoped>
.disable-item {
  span {
    color: #757575;
  }
}
.expand-area-container {
  padding: 0.5rem;
  @media (min-width: 768px) {
    padding: 1.5rem;
  }
}
</style>
